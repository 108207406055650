import { setLogLevel, setLogger, createInstance, logging, } from '@optimizely/optimizely-sdk';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OPTIMIZELY_API_TOKEN;
var OPTIMIZELY_POLL_TIMEOUT;
setLogLevel('info');
setLogger(logging.createLogger());
if (environment.optimizelyToken) {
    OPTIMIZELY_API_TOKEN = environment.optimizelyToken;
    OPTIMIZELY_POLL_TIMEOUT = 0;
}
var FeatureFlagService = /** @class */ (function () {
    function FeatureFlagService(http) {
        this.http = http;
    }
    FeatureFlagService.prototype.makeGetRequest = function (url) {
        return this.http.get(url);
    };
    FeatureFlagService.prototype.getFeature = function (featureName, forceUpdate) {
        var _this = this;
        if (forceUpdate === void 0) { forceUpdate = false; }
        var url = "https://cdn.optimizely.com/datafiles/" + OPTIMIZELY_API_TOKEN + ".json";
        var now = Date.now();
        if (!this.updatedAt ||
            forceUpdate ||
            now - this.updatedAt > OPTIMIZELY_POLL_TIMEOUT * 1000) {
            return this.makeGetRequest(url).pipe(mergeMap(function (res) {
                _this.optimizelyClientInstance = createInstance({ datafile: res });
                _this.updatedAt = Date.now();
                console.warn(_this.optimizelyClientInstance.isFeatureEnabled(featureName, 'non-null-string'));
                return of(_this.optimizelyClientInstance.isFeatureEnabled(featureName, 'non-null-string'));
            }));
        }
        return of(false);
    };
    FeatureFlagService.ngInjectableDef = i0.defineInjectable({ factory: function FeatureFlagService_Factory() { return new FeatureFlagService(i0.inject(i1.HttpClient)); }, token: FeatureFlagService, providedIn: "root" });
    return FeatureFlagService;
}());
export { FeatureFlagService };
