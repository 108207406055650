import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
var SettingsService = /** @class */ (function () {
    function SettingsService(apollo) {
        this.apollo = apollo;
        this.cache = {};
    }
    /**
     * Init settings cache
     */
    SettingsService.prototype.init = function () {
        var _this = this;
        // Set new cache expiry (5min)
        this.cache_expiry = Date.now() + 300 * 1000;
        var query = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n      query {\n        settings {\n          key\n          value\n        }\n      }\n    "], ["\n      query {\n        settings {\n          key\n          value\n        }\n      }\n    "])));
        return this.apollo
            .query({
            query: query,
            fetchPolicy: 'network-only',
        })
            .subscribe(function (_a) {
            var data = _a.data;
            var settings = data.settings;
            // Populate the cache
            _.each(settings, function (s) {
                _this.cache[s.key] = s.value;
            });
        });
    };
    /**
     * Find a setting
     */
    SettingsService.prototype.findValue = function (key) {
        var cacheStale = this.cache_expiry && Date.now() > this.cache_expiry;
        // Cache miss
        if (_.isEmpty(this.cache) || cacheStale) {
            // Refresh the cache for next invocation
            this.init();
        }
        // Cache hit
        return this.cache[key];
    };
    return SettingsService;
}());
export { SettingsService };
var templateObject_1;
