import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class TemplateService {
  private objectColorMap: any = {
    product: {
      displayName: 'Product',
      objectType: 'product',
      stroke: '#3cb44b',
      fill: '#3cb44b' + '88',
    },
    primary_logo: {
      displayName: 'Primary Logo',
      objectType: 'primary_logo',
      stroke: '#e6194b',
      fill: '#e6194b' + '88',
    },
    secondary_logo: {
      displayName: 'Secodary Logo',
      objectType: 'secondary_logo',
      stroke: '#f032e6',
      fill: '#f032e6' + '88',
    },
    lockup: {
      displayName: 'Lockup',
      objectType: 'lockup',
      stroke: '#bcf60c',
      fill: '#bcf60c' + '88',
    },

    headline_textbox: {
      displayName: 'Headline Textbox',
      objectType: 'headline_textbox',
      stroke: '#4363d8',
      fill: '#4363d8' + '88',
    },
    other_textbox: {
      displayName: 'Other Textbox',
      objectType: 'other_textbox',
      stroke: '#f58231',
      fill: '#f58231' + '88',
    },
    graphic_accent: {
      displayName: 'Graphic Accent',
      objectType: 'graphic_accent',
      stroke: '#000075',
      fill: '#000075' + '88',
    },
    photo_accent: {
      displayName: 'Photo Accent',
      objectType: 'photo_accent',
      stroke: '#46f0f0',
      fill: '#46f0f0' + '88',
    },
    button: {
      displayName: 'Button',
      objectType: 'button',
      stroke: '#008080',
      fill: '#008080' + '88',
    },
    banner: {
      displayName: 'Banner',
      objectType: 'banner',
      stroke: '#ffe119',
      fill: '#ffe119' + '88',
    },
    sticker: {
      displayName: 'Sticker',
      objectType: 'sticker',
      stroke: '#fabebe',
      fill: '#fabebe',
    },
    frame: {
      displayName: 'Frame',
      objectType: 'frame',
      stroke: '#e6beff',
      fill: '#e6beff' + '88',
    },
  };

  updateTemplateJson(imageUrl, canvasJson, canvasWidth, canvasHeight): any {
    canvasJson.objects = canvasJson.objects
      .map(o => {
        const objectType = this.getObjectType(o.type, o.fill);
        if (_.isNil(objectType)) {
          return null;
        }

        if (objectType == 'background') {
          o._key = `${objectType}_placeholder`;
          o.src = `${objectType}_placeholder`;
        } else {
          o._key = `${objectType}_placeholder_${o.id}`;
          o.src = `${objectType}_placeholder_${o.id}`;
        }
        o.stroke = null;
        o.strokeWidth = 0;
        o.type = 'image';
        o.width = o.width * o.scaleX;
        o.height = o.height * o.scaleY;
        o.scaleX = 1;
        o.scaleY = 1;
        return o;
      })
      .filter(o => o);
    canvasJson.backgroundImage.src = '';
    canvasJson.backgroundImage.crossOrigin = 'anonymous';
    canvasJson._originalImgUrl = imageUrl;
    canvasJson._canvasDimensions = {
      width: canvasWidth,
      height: canvasHeight,
    };
    return canvasJson;
  }

  isTemplateJsonValid(canvasJsonString): boolean {
    const canvasJson = JSON.parse(canvasJsonString);
    if (canvasJson.objects.length > 1) {
      return true;
    }
    return false;
  }

  getObjectFill(objectType: string) {
    return _.get(this.objectColorMap, `${objectType}`, null);
  }

  getObjectType(jsonType, fillColor) {
    let objectType;
    if (jsonType === 'image') {
      objectType = 'background';
    } else {
      const colorObjectMap = _.invertBy(this.objectColorMap, function(value) {
        return value.fill;
      });
      return _.get(colorObjectMap, `${fillColor}[0]`);
    }
    return objectType;
  }

  listObjectTypes(): Array<string> {
    return _.values(this.objectColorMap);
  }

  getObjectTypeInitialCount(): any {
    return _.mapValues(this.objectColorMap, function(o) {
      return 0;
    });
  }
}
