import { Router, } from '@angular/router';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AlertService } from '../../services/alert/alert.service';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, auth, alertService) {
        this.router = router;
        this.auth = auth;
        this.alertService = alertService;
    }
    // Check that user role is authenticated
    AuthGuard.prototype.checkLoggedIn = function () {
        var _this = this;
        return this.auth.isAuthenticated().pipe(mergeMap(function (_a) {
            var loggedIn = _a.loggedIn, user = _a.user;
            if (!loggedIn.value) {
                _this.alertService.contentAlert = {
                    type: 'warning',
                    text: 'Please log in to access the page.',
                    closable: false,
                };
                _this.router.navigate(['/login']);
            }
            return of(user);
        }));
    };
    // Check that user role meets minimum role on route
    AuthGuard.prototype.checkRole = function (user, route) {
        if (route.data && route.data.role) {
            if (user.role < route.data.role) {
                this.alertService.contentAlert = {
                    type: 'warning',
                    text: 'You are not allowed to access this page.',
                    closable: false,
                };
                this.router.navigate(['/login']);
            }
        }
        return of(true);
    };
    // Guard for component activation
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return this.checkLoggedIn().pipe(mergeMap(function (_a) {
            var value = _a.value;
            return _this.checkRole(value, route);
        }));
    };
    // Guard for child component activation
    AuthGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    // Guard for lazy loading components
    AuthGuard.prototype.canLoad = function (route) {
        var _this = this;
        return this.checkLoggedIn().pipe(mergeMap(function (_a) {
            var value = _a.value;
            return _this.checkRole(value, route);
        }));
    };
    return AuthGuard;
}());
export { AuthGuard };
