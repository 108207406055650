<clr-modal *ngIf="openModal" [(clrModalOpen)]="openModal" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">Please answer the following questions:</h3>
  <div class="modal-body">
    <form #taskProfileForm="ngForm">
      <section *ngFor="let question of taskProfileQuestions; let i = index;" class="form-block">

        <!-- Single Select -->
        <div *ngIf="question.questionType.id === questionTypes.single" class="form-group">
          <label for="single-{{i}}">{{ question.description }}</label>
          <div class="select">
            <select id="single-{{i}}" [(ngModel)]="model[question.id]" name="question.id">
              <option *ngFor="let option of question.options" [value]="option.value">
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>

        <!-- Multi Select -->
        <div *ngIf="question.questionType.id === questionTypes.multi" class="form-group">
          <label for="multi-{{i}}">{{ question.description }}</label>
          <div class="select multiple">
            <select id="multi-{{i}}" [(ngModel)]="model[question.id]" name="question.id" multiple>
              <option *ngFor="let option of question.options" [value]="option.value">
                {{ option.value }}
              </option>
            </select>
          </div>
        </div>
      </section>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline" (click)="onSkip()">Skip</button>
    <button type="button" class="btn btn-primary" (click)="onSubmit()">
      Submit
    </button>
  </div>
</clr-modal>
