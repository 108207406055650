import { Injectable } from '@angular/core';
import {
  setLogLevel,
  setLogger,
  createInstance,
  logging,
} from '@optimizely/optimizely-sdk';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

let OPTIMIZELY_API_TOKEN;
let OPTIMIZELY_POLL_TIMEOUT;

setLogLevel('info');
setLogger(logging.createLogger());

if (environment.optimizelyToken) {
  OPTIMIZELY_API_TOKEN = environment.optimizelyToken;
  OPTIMIZELY_POLL_TIMEOUT = 0;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  public optimizelyClientInstance;
  public updatedAt;

  constructor(private http: HttpClient) {}

  makeGetRequest(url): Observable<any> {
    return this.http.get(url);
  }

  getFeature(featureName: string, forceUpdate = false): Observable<boolean> {
    const url = `https://cdn.optimizely.com/datafiles/${OPTIMIZELY_API_TOKEN}.json`;
    const now = Date.now();
    if (
      !this.updatedAt ||
      forceUpdate ||
      now - this.updatedAt > OPTIMIZELY_POLL_TIMEOUT * 1000
    ) {
      return this.makeGetRequest(url).pipe(
        mergeMap(res => {
          this.optimizelyClientInstance = createInstance({ datafile: res });
          this.updatedAt = Date.now();
          console.warn(this.optimizelyClientInstance.isFeatureEnabled(
            featureName,
            'non-null-string'
          ))
          return of(
            this.optimizelyClientInstance.isFeatureEnabled(
              featureName,
              'non-null-string'
            )
          );
        })
      );
    }

    return of(false);
  }
}
