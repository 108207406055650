<clr-main-container>
  <!-- App Level Alerts -->
  <clr-alerts>
    <clr-alert *ngFor="let alert of alertService.appAlerts" [clrAlertType]="alert.type" [clrAlertAppLevel]="true">
      <div class="alert-item">
        <span class="alert-text">{{alert.text}}</span>
        <div class="alert-actions" *ngIf="alert.action">
          <button class="btn alert-action" routerLink="{{ alert.actionLink }}">{{alert.action}}</button>
        </div>
      </div>
    </clr-alert>
  </clr-alerts>

  <!-- Header for md and above -->
  <clr-header>
    <div class="branding">
      <a class="nav-link" [routerLink]="['/tasks']">
        <span class="clr-icon clr-clarity-logo"></span>
      </a>
    </div>
    <div class="header-nav show-gt-sm">
      <!--
      <a class="nav-link" [routerLink]="['/']" routerLinkActive="active">
        <span class="nav-text">Home</span>
      </a>
      -->
      <a *ngIf="(auth.loggedIn$ | async) && (auth.isRegistered$ | async)" class="nav-link" [routerLink]="['/tasks']"
        routerLinkActive="active">
        <span class="nav-text">Tasks</span>
      </a>
      <a *ngIf="(auth.loggedIn$ | async) && (auth.isAdmin$ | async)" class="nav-link" [routerLink]="['/admin']"
        routerLinkActive="active">
        <span class="nav-text">Admin</span>
      </a>
      <a *ngIf="(auth.loggedIn$ | async) && (auth.isRegistered$ | async)" class="nav-link" [routerLink]="['/activity']"
        routerLinkActive="active">
        <span class="nav-text">Activity</span>
      </a>
      <a *ngIf="!(auth.loggedIn$ | async)" class="nav-link" [routerLink]="['/login']" routerLinkActive="active">
        <span class="nav-text">Login</span>
      </a>
      <a *ngIf="(auth.loggedIn$ | async)" class="nav-link show-sm-only" (click)="onClickLogout()" routerLinkActive="active">
        <span class="nav-text">Logout</span>
      </a>
    </div>
    <div class="header-actions">
      <clr-dropdown *ngIf="(auth.loggedIn$ | async)" class="show-gt-sm">
        <button class="nav-text" clrDropdownTrigger>
          {{ user.name }}
          <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a class="logout-link" (click)="onClickLogout()">
            <span>Logout</span>
          </a>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </clr-header>

  <!-- Right hamburger for sm only -->
  <nav class="sidenav show-sm-only" [clr-nav-level]="2">
    <section class="sidenav-content">
      <a *ngIf="(auth.loggedIn$ | async) && (auth.isRegistered$ | async)" class="nav-link" [routerLink]="['/tasks']"
        routerLinkActive="active">
        <span class="nav-text">Tasks</span>
      </a>
      <a *ngIf="(auth.loggedIn$ | async) && (auth.isRegistered$ | async)" class="nav-link" [routerLink]="['/activity']"
        routerLinkActive="active">
        <span class="nav-text">Activity</span>
      </a>
      <section *ngIf="(auth.loggedIn$ | async) && (auth.isAdmin$ | async)" class="nav-group collapsible">
        <input id="admin-page" type="checkbox">
        <label for="admin-page">Admin</label>
        <ul class="nav-list">
          <li>
            <a class="nav-link" [routerLink]="['/admin/analytics']" routerLinkActive="active">
              Analytics
            </a>
          </li>
          <li>
            <a *ngIf="(auth.loggedIn$ | async) && (auth.isSuperAdmin$ | async)" class="nav-link" [routerLink]="['/admin/users']"
              routerLinkActive="active">
              Users
            </a>
          </li>
          <li>
            <a *ngIf="(auth.loggedIn$ | async) && (auth.isSuperAdmin$ | async)" class="nav-link" [routerLink]="['/admin/invites']"
              routerLinkActive="active">
              Invites
            </a>
          </li>
          <li>
            <a *ngIf="(auth.loggedIn$ | async) && (auth.isAdmin$ | async)" class="nav-link" [routerLink]="['/admin/tasks']"
              routerLinkActive="active">
              Tasks
            </a>
          </li>
        </ul>
      </section>
      <a *ngIf="!(auth.loggedIn$ | async)" class="nav-link" [routerLink]="['/login']" routerLinkActive="active">
        <span class="nav-text">Login</span>
      </a>
      <a *ngIf="(auth.loggedIn$ | async)" class="nav-link show-sm-only" (click)="onClickLogout()" routerLinkActive="active">
        <span class="nav-text">Logout</span>
      </a>
    </section>
  </nav>

  <div class="content-container">
    <div class="content-area">
      <!-- App Spinner -->
      <div class="clr-row clr-justify-content-center">
        <span [hidden]="!(appSpinner.loading$ | async)" class="spinner spinner-lg"></span>
      </div>

      <!-- Content Level Alert -->
      <div class="clr-row clr-justify-content-end content-alert">
        <div class="clr-col-xs-12 clr-col-md-4">
          <clr-alert *ngIf="alertService.contentAlert" [clrAlertType]="alertService.contentAlert.type"
            [clrAlertClosable]="false">
            <div clr-alert-item class="alert-item">
              <span class="alert-text" [innerHTML]="alertService.contentAlert.text | safeHtml">
              </span>
              <div class="alert-actions">
                <span *ngIf="alertService.contentAlert.closable" (click)="alertService.closeContentAlert()" class="close-alert">
                  <clr-icon shape="times"></clr-icon>
                </span>
              </div>
            </div>
          </clr-alert>
        </div>
      </div>

      <router-outlet [hidden]="appSpinner.loading$ | async"></router-outlet>
    </div>
  </div>
</clr-main-container>
