import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  showSpinner() {
    this.loading$.next(true);
  }

  hideSpinner() {
    this.loading$.next(false);
  }
}
