import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
var UserService = /** @class */ (function () {
    function UserService(apollo) {
        this.apollo = apollo;
    }
    /**
     * Get authenticated user profile
     */
    UserService.prototype.me = function () {
        var query = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n      query {\n        me {\n          id\n          completedTaskProfiles\n        }\n      }\n    "], ["\n      query {\n        me {\n          id\n          completedTaskProfiles\n        }\n      }\n    "])));
        return this.apollo.query({
            query: query,
            fetchPolicy: 'network-only',
        });
    };
    /**
     * Create a new user or update authenticated user's profile
     *
     * This method handles both create and update because user might
     * already have an account from another provider. In that case, we
     * only update his details.
     *
     * Email is the only way we can uniquely identify a user to multiple
     * federated identities in our backend. But email may change, so we
     * will need to provide email change feature.
     *
     * @param {Object} opts Object containing properties to update
     * @param {string} opts.provider Provider name eg. google, facebook
     * @param {string} opts.token Access token returned by provider when user has
     * successfully authenticated
     * @param {string} opts.email User's email
     * @param {string} [opts.name] User's name
     * @param {string} [opts.googleId] Google account sub claim
     * @param {string} [opts.facebookId] Facebook account ID
     */
    UserService.prototype.oauthSignin = function (opts) {
        var mutation = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n      mutation oauthSignin(\n        $provider: String!\n        $token: String!\n        $email: String!\n        $name: String\n        $googleId: String\n        $facebookId: String\n      ) {\n        oauthSignin(\n          provider: $provider\n          token: $token\n          email: $email\n          name: $name\n          googleId: $googleId\n          facebookId: $facebookId\n        ) {\n          id\n          role {\n            id\n          }\n          token\n        }\n      }\n    "], ["\n      mutation oauthSignin(\n        $provider: String!\n        $token: String!\n        $email: String!\n        $name: String\n        $googleId: String\n        $facebookId: String\n      ) {\n        oauthSignin(\n          provider: $provider\n          token: $token\n          email: $email\n          name: $name\n          googleId: $googleId\n          facebookId: $facebookId\n        ) {\n          id\n          role {\n            id\n          }\n          token\n        }\n      }\n    "])));
        var variables = opts;
        return this.apollo.mutate({
            mutation: mutation,
            variables: variables,
        });
    };
    return UserService;
}());
export { UserService };
var templateObject_1, templateObject_2;
