import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ImageService {
  private annotatorUrl: string = environment.annotatorUrl;
  constructor(private http: HttpClient) {}

  private makeGetRequest(subUrl): Observable<any> {
    const token = localStorage.getItem('pencil-hit-token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    return this.http.get<any>(this.annotatorUrl + subUrl, {
      headers: httpHeaders,
    });
  }

  getTasks(): Observable<any> {
    return this.makeGetRequest('/api/tasks');
  }

  getNextImage(taskId: number): Observable<any> {
    return this.makeGetRequest(`/api/task/${taskId}/question`);
  }

  getQuestionCount(taskId: number): Observable<any> {
    return this.makeGetRequest(`/api/task/${taskId}/question/count`);
  }

  saveImage(annotation): Observable<any> {
    const token = localStorage.getItem('pencil-hit-token');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    return this.http
      .put<any>(this.annotatorUrl + '/api/question', annotation, {
        headers: httpHeaders,
      })
      .pipe(catchError(this.handleError<any>('saveImage')));
  }

  getAccuracy(taskId: number): Observable<any> {
    return this.makeGetRequest(`/api/task/${taskId}/accuracy`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
}
