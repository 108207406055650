import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
var TasksService = /** @class */ (function () {
    function TasksService(apollo) {
        this.apollo = apollo;
    }
    /**
     * Get available tasks for user
     */
    TasksService.prototype.getTasks = function (isAdmin) {
        var query;
        if (isAdmin) {
            query = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n        query {\n          allTasks: tasks {\n            id\n            description\n            instructions\n            taskType {\n              id\n            }\n            validation\n            enabled\n            maxLabels\n            currentLabelCount\n            payoutPerQuestion\n            minAccuracy\n          }\n        }\n      "], ["\n        query {\n          allTasks: tasks {\n            id\n            description\n            instructions\n            taskType {\n              id\n            }\n            validation\n            enabled\n            maxLabels\n            currentLabelCount\n            payoutPerQuestion\n            minAccuracy\n          }\n        }\n      "])));
        }
        else {
            query = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n        query {\n          allTasks: tasks(enabled: true, validated: true) {\n            id\n            description\n            instructions\n            taskType {\n              id\n            }\n            validation\n            enabled\n            maxLabels\n            currentLabelCount\n            payoutPerQuestion\n            minAccuracy\n          }\n        }\n      "], ["\n        query {\n          allTasks: tasks(enabled: true, validated: true) {\n            id\n            description\n            instructions\n            taskType {\n              id\n            }\n            validation\n            enabled\n            maxLabels\n            currentLabelCount\n            payoutPerQuestion\n            minAccuracy\n          }\n        }\n      "])));
        }
        return this.apollo.query({
            query: query,
            fetchPolicy: 'network-only',
        });
    };
    /**
     * Get task profile questions
     */
    TasksService.prototype.getTaskProfileQuestions = function (taskId) {
        var query = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n      query taskProfileQuestions($taskId: ID!) {\n        taskProfileQuestions(taskId: $taskId) {\n          id\n          description\n          questionType {\n            id\n          }\n          options {\n            value\n          }\n        }\n      }\n    "], ["\n      query taskProfileQuestions($taskId: ID!) {\n        taskProfileQuestions(taskId: $taskId) {\n          id\n          description\n          questionType {\n            id\n          }\n          options {\n            value\n          }\n        }\n      }\n    "])));
        return this.apollo.query({
            query: query,
            variables: {
                taskId: taskId,
            },
            fetchPolicy: 'network-only',
        });
    };
    /**
     * Get total no. of items in validation set for a task
     *
     * @param {number} id Task Id
     */
    TasksService.prototype.getValidationCount = function (id) {
        var query = gql(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n      query validationLabels($id: ID!) {\n        validationCount: validationLabels(id: $id)\n      }\n    "], ["\n      query validationLabels($id: ID!) {\n        validationCount: validationLabels(id: $id)\n      }\n    "])));
        return this.apollo.query({
            query: query,
            variables: {
                id: id,
            },
            fetchPolicy: 'network-only',
        });
    };
    /**
     * Add task question response to user profile
     *
     * @param {number} taskId Task ID
     * @param {number} questionId Task profile question ID
     * @param {string} [value] Value to save to user profile
     */
    TasksService.prototype.updateUserProfile = function (taskId, questionId, value) {
        var mutation = gql(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n      mutation updateUserProfile(\n        $taskId: ID!\n        $questionId: ID!\n        $value: String\n      ) {\n        updateUserProfile(\n          taskId: $taskId\n          questionId: $questionId\n          value: $value\n        )\n      }\n    "], ["\n      mutation updateUserProfile(\n        $taskId: ID!\n        $questionId: ID!\n        $value: String\n      ) {\n        updateUserProfile(\n          taskId: $taskId\n          questionId: $questionId\n          value: $value\n        )\n      }\n    "])));
        return this.apollo.mutate({
            mutation: mutation,
            variables: {
                taskId: taskId,
                questionId: questionId,
                value: value,
            },
        });
    };
    return TasksService;
}());
export { TasksService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
