import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { HomeComponent } from './components/home/home.component';
import { AnnotatorComponent } from './components/annotator/annotator.component';

import { AuthGuard } from './services/auth/auth.guard';
import { Roles } from './enums/roles.enum';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: '', component: HomeComponent },
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'tasks', component: TasksComponent, canActivate: [AuthGuard] },
  {
    path: 'task',
    loadChildren: './modules/task/task.module#TaskModule',
    canLoad: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: './modules/admin/admin.module#AdminModule',
    canLoad: [AuthGuard],
    data: {
      role: Roles.admin,
    },
  },
  {
    path: 'activity',
    loadChildren: './modules/activity/activity.module#ActivityModule',
    canLoad: [AuthGuard],
    data: {
      role: Roles.registered_user,
    },
  },
  {
    path: 'annotator/:id',
    component: AnnotatorComponent,
    canLoad: [AuthGuard],
    data: {
      role: Roles.registered_user,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
