import { Injectable } from '@angular/core';

export interface AppLevelAlert {
  name: string;
  type: 'info' | 'warning' | 'success' | 'danger';
  text: string;
  action: string;
  actionLink: any;
}

export interface ContentLevelAlert {
  type: 'info' | 'warning' | 'success' | 'danger';
  text: string;
  closable: boolean;
}

@Injectable()
export class AlertService {
  appAlerts: AppLevelAlert[] = [];
  contentAlert?: ContentLevelAlert;

  closeContentAlert() {
    this.contentAlert = null;
  }
}
