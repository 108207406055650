import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {
  constructor(private apollo: Apollo) {}

  /**
   * Get authenticated user profile
   */
  me(): Observable<ApolloQueryResult<any>> {
    const query = gql`
      query {
        me {
          id
          completedTaskProfiles
        }
      }
    `;

    return this.apollo.query<any>({
      query,
      fetchPolicy: 'network-only',
    });
  }

  /**
   * Create a new user or update authenticated user's profile
   *
   * This method handles both create and update because user might
   * already have an account from another provider. In that case, we
   * only update his details.
   *
   * Email is the only way we can uniquely identify a user to multiple
   * federated identities in our backend. But email may change, so we
   * will need to provide email change feature.
   *
   * @param {Object} opts Object containing properties to update
   * @param {string} opts.provider Provider name eg. google, facebook
   * @param {string} opts.token Access token returned by provider when user has
   * successfully authenticated
   * @param {string} opts.email User's email
   * @param {string} [opts.name] User's name
   * @param {string} [opts.googleId] Google account sub claim
   * @param {string} [opts.facebookId] Facebook account ID
   */
  oauthSignin(opts: any): Observable<any> {
    const mutation = gql`
      mutation oauthSignin(
        $provider: String!
        $token: String!
        $email: String!
        $name: String
        $googleId: String
        $facebookId: String
      ) {
        oauthSignin(
          provider: $provider
          token: $token
          email: $email
          name: $name
          googleId: $googleId
          facebookId: $facebookId
        ) {
          id
          role {
            id
          }
          token
        }
      }
    `;
    const variables = opts;

    return this.apollo.mutate({
      mutation,
      variables,
    });
  }
}
