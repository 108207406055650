import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';

@Injectable()
export class TasksService {
  constructor(private apollo: Apollo) {}

  /**
   * Get available tasks for user
   */
  getTasks(isAdmin): Observable<ApolloQueryResult<any>> {
    let query;

    if (isAdmin) {
      query = gql`
        query {
          allTasks: tasks {
            id
            description
            instructions
            taskType {
              id
            }
            validation
            enabled
            maxLabels
            currentLabelCount
            payoutPerQuestion
            minAccuracy
          }
        }
      `;
    } else {
      query = gql`
        query {
          allTasks: tasks(enabled: true, validated: true) {
            id
            description
            instructions
            taskType {
              id
            }
            validation
            enabled
            maxLabels
            currentLabelCount
            payoutPerQuestion
            minAccuracy
          }
        }
      `;
    }

    return this.apollo.query<any>({
      query,
      fetchPolicy: 'network-only',
    });
  }

  /**
   * Get task profile questions
   */
  getTaskProfileQuestions(taskId: number): Observable<ApolloQueryResult<any>> {
    const query = gql`
      query taskProfileQuestions($taskId: ID!) {
        taskProfileQuestions(taskId: $taskId) {
          id
          description
          questionType {
            id
          }
          options {
            value
          }
        }
      }
    `;

    return this.apollo.query<any>({
      query,
      variables: {
        taskId,
      },
      fetchPolicy: 'network-only',
    });
  }

  /**
   * Get total no. of items in validation set for a task
   *
   * @param {number} id Task Id
   */
  getValidationCount(id: number): Observable<ApolloQueryResult<any>> {
    const query = gql`
      query validationLabels($id: ID!) {
        validationCount: validationLabels(id: $id)
      }
    `;

    return this.apollo.query<any>({
      query,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    });
  }

  /**
   * Add task question response to user profile
   *
   * @param {number} taskId Task ID
   * @param {number} questionId Task profile question ID
   * @param {string} [value] Value to save to user profile
   */
  updateUserProfile(
    taskId: number,
    questionId: number,
    value?: string
  ): Observable<any> {
    const mutation = gql`
      mutation updateUserProfile(
        $taskId: ID!
        $questionId: ID!
        $value: String
      ) {
        updateUserProfile(
          taskId: $taskId
          questionId: $questionId
          value: $value
        )
      }
    `;
    return this.apollo.mutate({
      mutation,
      variables: {
        taskId,
        questionId,
        value,
      },
    });
  }
}
