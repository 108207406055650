import {
  Component,
  ElementRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

import { AuthService } from './../../services/auth/auth.service';
import { GoogleService } from './../../services/oauth/google.service';
import { FacebookService } from './../../services/oauth/facebook.service';
import { environment } from '../../../environments/environment';

declare const FB: any;
declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscribe: boolean = true;
  loginForm: FormGroup;
  loading: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private google: GoogleService,
    private facebook: FacebookService
  ) {
    // Init FB sdk
    // FB.init({
    //   appId: environment.fbAppID,
    //   xfbml: true,
    //   version: 'v2.8',
    // });
    // FB.AppEvents.logPageView();

    // Init Google sdk
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: environment.googleAppID,
      });
    });

    this.router.events.subscribe(() => {
      // Hide spinner if present
      this.loading = false;
    });
  }

  ngOnInit() {
    this.initForm();

    // Register facebook login callback
    this.facebook.onLogin();

    // Render google login button
    this.google.renderButton();

    this.auth
      .isAuthenticated()
      .pipe(takeWhile(() => this.subscribe))
      .subscribe(
        ({ loggedIn }) => {
          if (loggedIn.value) {
            this.router.navigate(['/tasks']);
          }
        },
        error => console.log(error)
      );
  }

  ngAfterViewInit() {
    document.querySelector('.content-container').classList.add('gradient-bg');
    document.querySelector('clr-header').classList.add('hide');
  }

  ngOnDestroy() {
    if (this.subscribe) {
      this.subscribe = false;
    }

    document
      .querySelector('.content-container')
      .classList.remove('gradient-bg');
    document.querySelector('clr-header').classList.remove('hide');
  }

  /**
   * Email/password fallback
   */
  initForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onEmailLogin(value: any) {
    const email = value.email;
    const password = value.password;

    // this.auth.signIn(email, password).subscribe(
    //   result => {
    //     this.router.navigate(['/tasks']);
    //   },
    //   error => console.log(error)
    // );
  }

  onSocialLogin() {
    this.loading = true;
  }
}
