import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ClrAlerts } from '@clr/angular';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';

import { AuthService } from './services/auth/auth.service';
import { AlertService } from './services/alert/alert.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { SettingsService } from './services/settings/settings.service';
import { Roles } from './enums/roles.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(ClrAlerts)
  alertsContainer: ClrAlerts;
  private subscribe: boolean = true;
  roles: any;
  user: any;
  active: boolean = false;

  constructor(
    public auth: AuthService,
    public alertService: AlertService,
    public appSpinner: SpinnerService,
    private router: Router,
    private settingsService: SettingsService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.appSpinner.showSpinner();
      }

      if (event instanceof NavigationEnd) {
        // App component is only init once, so we have to watch for route
        // changes to rerun this method
        // this.handleSignupAlert();
        this.handleSignin();

        this.appSpinner.hideSpinner();
      }

      if (event instanceof NavigationError) {
        this.appSpinner.hideSpinner();
      }
    });
  }

  ngOnInit() {
    this.roles = Roles;
    this.handleSignin();
    this.settingsService.init();
  }

  ngOnDestroy() {
    if (this.subscribe) {
      this.subscribe = false;
    }
  }

  private handleSignupAlert(loggedIn) {
    if (!loggedIn.value) {
      this.addSignupAlert();
    } else {
      this.removeSignupAlert();
    }
  }

  private addSignupAlert() {
    const signupAlertIndex = _.findIndex(this.alertService.appAlerts, {
      name: 'signupAlert',
    });

    // Only add if alert doesnt already exist
    if (signupAlertIndex === -1) {
      this.alertService.appAlerts.push({
        name: 'signupAlert',
        type: 'info',
        text: 'Creativity at scale. Try Pencil now.',
        action: 'Sign in',
        actionLink: ['/login'],
      });

      if (!this.alertsContainer.currentAlert) {
        this.alertsContainer.multiAlertService.current = 0;
      }
    }
  }

  private handleSignin() {
    this.auth
      .isAuthenticated()
      .pipe(takeWhile(() => this.subscribe))
      .subscribe(
        ({ loggedIn, user }) => {
          // this.handleSignupAlert(loggedIn);
          this.user = user.value;
        },
        error => console.log(error)
      );
  }

  private removeSignupAlert() {
    this.alertService.appAlerts = _.filter(this.alertService.appAlerts, a => {
      return a.name !== 'signupAlert';
    });
  }

  onClickLogout() {
    localStorage.removeItem('pencil-hit-token');
    this.auth.loggedIn$.next(false);
    this.auth.user$.next({ role: Roles.public });
    this.router.navigate(['/login']);
  }
}
