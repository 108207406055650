import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { ClarityModule } from '@clr/angular';
import { HttpClientModule } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { MarkdownModule } from 'angular2-markdown';
import { MarkdownService } from 'angular2-markdown';
import * as Raven from 'raven-js';
import * as _ from 'lodash';

import { environment } from '../environments/environment';

// Shared Module
import { SharedModule } from './shared/shared.module';

// VisualEditorModule
import { VisualEditorModule } from 'visual-editor';

// App
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

// Components
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { TasksService } from './components/tasks/tasks.service';
import { TaskModalComponent } from './components/tasks/task-modal/task-modal.component';
import { TaskProfilingModalComponent } from './components/tasks/task-profiling-modal/task-profiling-modal.component';
import { AnnotatorComponent } from './components/annotator/annotator.component';

// Services
import { AlertService } from './services/alert/alert.service';
import { SpinnerService } from './services/spinner/spinner.service';
import { AuthService } from './services/auth/auth.service';
import { AuthGuard } from './services/auth/auth.guard';
import { GoogleService } from './services/oauth/google.service';
import { FacebookService } from './services/oauth/facebook.service';
import { UserService } from './services/user/user.service';
import { SettingsService } from './services/settings/settings.service';
import { ImageService } from './services/annotator/image.service';
import { TemplateService } from './services/annotator/template.service';
import { MbrService } from './services/annotator/mbr.service';
import { OldAnnotatorComponent } from './components/annotator/old-annotator/old-annotator.component';
import { NewAnnotatorComponent } from './components/annotator/new-annotator/new-annotator.component';
import { FeatureFlagService } from './services/featureFlag/featureflag.service';

// Sentry for client-side logging
Raven.config(environment.ravenUrl).install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (location.hostname !== 'localhost') {
      Raven.captureException(err);
    } else {
      console.error(err);
    }
  }
}

// Disable console.log if not localhost
if (location.hostname !== 'localhost') {
  console.log = function() {};
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ClarityModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    MarkdownModule,
    SharedModule,
    VisualEditorModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SignupComponent,
    TasksComponent,
    TaskModalComponent,
    TaskProfilingModalComponent,
    AnnotatorComponent,
    OldAnnotatorComponent,
    NewAnnotatorComponent,
  ],
  providers: [
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    AuthService,
    AuthGuard,
    GoogleService,
    FacebookService,
    TasksService,
    MarkdownService,
    UserService,
    AlertService,
    SpinnerService,
    SettingsService,
    ImageService,
    TemplateService,
    MbrService,
    FeatureFlagService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(apollo: Apollo, httpLink: HttpLink) {
    const http = httpLink.create({
      uri: environment.apiUrl,
      method: 'POST',
    });

    // Authorization Header
    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: new HttpHeaders().set(
          'Authorization',
          `Bearer ${localStorage.getItem('pencil-hit-token') || null}`
        ),
      });

      return forward(operation);
    });

    // APIG Header
    const apigMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers }) => ({
        headers: headers.append('x-api-key', environment.apigToken || null),
      }));

      return forward(operation);
    });

    // Afterware Handler
    // Need to do this because we are using lambda proxy integration
    // which returns a { statusCode, headers, body } response object
    // whereas Apollo client expects the body object directly
    const afterwareLink = new ApolloLink((operation, forward) => {
      return forward(operation).map(response => {
        const { body } = response;

        return JSON.parse(body);
      });
    });

    // Error Handler
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      const unauthorizedError = _.findIndex(graphQLErrors, {
        message: 'Unauthorized',
      });

      if (unauthorizedError !== -1) {
        // TODO: handle unauthorized error
      }

      // TODO: clientside error handling with Sentry
    });

    const cache = new InMemoryCache();

    apollo.create({
      cache,
      link: from([
        authMiddleware,
        apigMiddleware,
        errorLink.concat(afterwareLink.concat(http)),
      ]),
    });
  }
}
