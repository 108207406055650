<div class="container">
  <div class="clr-row">
    <div class="clr-col-10">
      <p>Template annotator</p>
    </div>
    <div class="clr-col-2">
      <p style="text-align:right">
        Accuracy: <span class="badge badge-blue">{{ accuracy }}</span> Count:
        <span class="badge badge-blue">{{ questionCount }}</span>
      </p>
    </div>
  </div>
  <hr />
  <div class="clr-row clr-align-items-start">
    <div class="clr-col-md-2" *ngIf="!errorMessage">
      <div
        class="card text-center"
        style="margin-top: 0px; background: none; border: 0px; box-shadow: 0px 0px"
      >
        <button
          class="btn btn-success"
          style="width:100%"
          (click)="saveImage()"
        >
          Save & Continue
        </button>
      </div>

      <div class="card" style="margin-top: 0px">
        <div class="card-header">Box Type</div>
        <clr-vertical-nav
          style="width:100%; padding:0px; background: white"
          *ngFor="let ot of objectTypes"
        >
          <div class="clr-row" style="padding: 0px">
            <div class="clr-col-9" style="padding: 0px">
              <a clrVerticalNavLink (click)="addFigure(ot.objectType)">{{
                ot.displayName
              }}</a>
            </div>
            <div class="clr-col-3" style="text-align: right">
              <div
                class="color-box"
                [ngStyle]="{
                  'background-color': ot.fill,
                  'border-color': ot.stroke
                }"
              ></div>
            </div>
          </div>
        </clr-vertical-nav>
      </div>
    </div>
    <div class="clr-col">
      <div (keydown)="canvasKeyboardEvent($event)" tabindex="0">
        <canvas id="canvas"></canvas>
      </div>
    </div>
    <div class="clr-col-md-3" *ngIf="!errorMessage">
      <div class="card" style="margin-top: 0px">
        <div class="card-header">Options</div>
        <div class="card-block text-center">
          <div
            class="btn-group btn-primary btn-icon tooltip tooltip-xs"
            role="group"
            style="padding-bottom: 5px"
          >
            <button
              class="btn btn-danger-outline tooltip tooltip-xs"
              [disabled]="!selected"
              (click)="removeSelected()"
            >
              <clr-icon shape="times"></clr-icon
              ><span class="tooltip-content">Remove box</span>
            </button>
            <button
              class="btn btn-info-outline tooltip tooltip-xs"
              [disabled]="!selected"
              (click)="sendToBack()"
            >
              <clr-icon shape="download"></clr-icon
              ><span class="tooltip-content">Send Backwards</span>
            </button>
            <button
              class="btn btn-info-outline tooltip tooltip-xs"
              [disabled]="!selected"
              (click)="bringToFront()"
            >
              <clr-icon shape="upload"></clr-icon
              ><span class="tooltip-content">Bring Forward</span>
            </button>
            <button
              class="btn btn-info-outline tooltip tooltip-xs"
              role="tooltip"
              aria-haspopup="true"
              [disabled]="!selected || selected.type == 'group'"
              (click)="clone(false)"
            >
              <clr-icon shape="copy"></clr-icon
              ><span class="tooltip-content">Copy</span>
            </button>
            <button
              class="btn btn-info-outline tooltip tooltip-xs"
              role="tooltip"
              aria-haspopup="true"
              [disabled]="!selected || selected.type == 'group'"
              (click)="clone(true)"
            >
              <clr-icon shape="clone"></clr-icon
              ><span class="tooltip-content">Clone</span>
            </button>
          </div>
          <div style="padding-bottom: 5px; width: 100%">
            <button
              type="button"
              class="btn btn-danger"
              style="text-align: center"
              (click)="removeAllObjects()"
            >
              <clr-icon shape="times"></clr-icon> Remove All
            </button>
          </div>
          <!-- <div>
            <button
              type="button"
              class="btn btn-danger-outline"
              style="text-align: center"
              (click)="clearPoints()"
            >
              <clr-icon shape="times"></clr-icon> Clear Points
            </button>
          </div> -->
        </div>
        <div class="card-footer">
          <span>Note</span>
          <ul
            class="list-group"
            style="font-size: 0.75em; padding: 0px; margin: 0px; width: 100%"
          >
            <li
              class="list-group-item"
              style="padding: 0px; margin: 0px; width: 100%"
            >
              <clr-icon shape="clone"></clr-icon
              ><span>Clone - Create box for identical object</span>
            </li>
            <li
              class="list-group-item"
              style="padding: 0px; margin: 0px; width: 100%"
            >
              <clr-icon shape="copy"></clr-icon
              ><span>Copy - Create same box type but NOT identical object</span>
            </li>
          </ul>
        </div>
        <div class="card-footer">
          <span>Keyboard Shortcuts</span>
          <ul
            class="list-group"
            style="font-size: 0.75em; padding: 0px; margin: 0px; width: 100%"
          >
            <li
              class="list-group-item"
              style="padding: 0px; margin: 0px; width: 100%"
            >
              <clr-icon shape="copy"></clr-icon><span>Copy - Ctrl/Cmd + V</span>
            </li>
            <li
              class="list-group-item"
              style="padding: 0px; margin: 0px; width: 100%"
            >
              <clr-icon shape="clone"></clr-icon
              ><span>Clone - Shift + Ctrl/Cmd + V</span>
            </li>
            <li
              class="list-group-item"
              style="padding: 0px; margin: 0px; width: 100%"
            >
              <clr-icon shape="times"></clr-icon
              ><span>Remove - Delete/Del</span>
            </li>
            <li
              class="list-group-item"
              style="padding: 0px; margin: 0px; width: 100%"
            >
              <clr-icon shape="times"></clr-icon
              ><span>Remove All - Shift + Delete/Del</span>
            </li>
            <li
              class="list-group-item"
              style="padding: 0px; margin: 0px; width: 100%"
            >
              <clr-icon shape="download"></clr-icon
              ><span>Send Backwards - Shift + Down arrow</span>
            </li>
            <li
              class="list-group-item"
              style="padding: 0px; margin: 0px; width: 100%"
            >
              <clr-icon shape="upload"></clr-icon
              ><span>Bring Forward - Shift + Up arrow</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="errorMessage">
    <p>{{ errorMessage }}</p>
  </div>
  <!-- <div class="clr-row" *ngIf="!errorMessage">
      <div class="clr-col-xl-12">
          <div class="card">
              <div class="card-header">
                  <button type="button" class="btn btn-primary btn-sm" (click)="rasterizeJSON()">
          <i class="fa fa-refresh" aria-hidden="true"></i> Render to json
        </button>
              </div>
              <div class="card-body">
                  <pre>{{ json }}</pre>
              </div>
          </div>
      </div>
  </div> -->
  <div class="clr-row">
    <div class="clr-col-xl-12">
      <div class="card">
        <div class="card-header">
          <button
            class="btn btn-primary"
            *ngIf="!helpShown"
            (click)="showHelp()"
          >
            Show Instructions
          </button>
          <button
            class="btn btn-primary"
            *ngIf="helpShown"
            (click)="hideHelp()"
          >
            Hide Instructions
          </button>
          <button
            class="btn btn-primary"
            *ngIf="!tipsShown"
            (click)="showTips()"
          >
            Show Tips & Tricks
          </button>
          <button
            class="btn btn-primary"
            *ngIf="tipsShown"
            (click)="hideTips()"
          >
            Hide Tips & Tricks
          </button>
          <button
            class="btn btn-primary"
            *ngIf="!comparison"
            (click)="showComparison()"
          >
            Show Last Comparison
          </button>
          <button
            class="btn btn-primary"
            *ngIf="comparison"
            (click)="hideComparison()"
          >
            Hide Last Comparison
          </button>
        </div>
        <div class="card-block" *ngIf="helpShown">
          <div>
            <h4>
              This task requires you to draw tight bounding boxes around
              different types of objects in a banner ad, creating a template of
              sorts from each ad example.
            </h4>
          </div>
          <div>
            <h4>Note</h4>
            <ul>
              <li>
                <strong>ACCURACY:</strong> WE REQUIRE AN ACCURACY OF 60% FOR
                THIS TASK.
              </li>
              <li>
                <strong>PAY:</strong> EACH LABELLED AD PAYS $0.05 IF OVERALL
                TASK ACCURACY IS REACHED.
              </li>
            </ul>
          </div>
          <div>
            <h4>There are 12 object types:</h4>
            <ol>
              <li>
                <strong>Product.</strong> This one’s obvious, typically a photo
                of the product in question. Not all ads (EG. those for services)
                have an explicit product to be labelled
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/74b3852c-22f7-4d1e-b014-54726e4d8d1c.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Primary logo.</strong> This is the main logo related to
                the product or company in the ad
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/c4f3f3b5-dd29-43c4-8ceb-528d0265ab59.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Secondary logo.</strong> This is typically a sub-brand
                or product logo, or the logo of a retailer as opposed to the
                main brand logo
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/04de8be2-6bb1-476b-9b63-7604b7bf51e6.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Headline textbox.</strong> This is typically the largest
                text in the ad and should be hard to miss
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/2b931a52-974b-4b09-a332-44ba8cb510f4.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Other textbox.</strong> This is for all other text other
                than the main headline
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/e7aa5fa6-0bb5-49bf-b958-6101c8827355.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Graphic accent.</strong> This is things like icons,
                illustrations or any vector-based or graphical accent typically
                scattered around the ad or used to accentuate the product or
                design
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/e5adf1f8-2171-4a69-976e-2c50309b9a5d.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Photo accent.</strong> This is for objects cropped from
                photos and scattered around the ad or used to accentuate the
                product or design
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/c5023802-a912-4abb-ba83-374d4e300564.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Button.</strong> This is a graphic that contains a text
                call to action like “click here” or “buy now”
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/37d14403-02e7-452d-8bc8-4f9eef4d0402.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Lockup.</strong> This is a graphic, usually with
                text/logos/buttons, that touches 2 or 3 edges of the ad
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/b1b0ee23-54ef-4112-af0c-3aca08a88ac3.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Banner.</strong> This is a graphic, usually rectangle
                with text, that touches 0 or 1 edge of the ad
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/0d6aa786-0c2c-4669-8c5f-df8ded9ee12d.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Sticker.</strong> This is a graphic, usually round with
                text, that touches 0 or 1 edge of the ad
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/20c4ef66-e543-4a73-b2cd-9f6de56c23fc.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
              <li>
                <strong>Frame.</strong> This is a graphic that touches all 4
                edges of the ad
                <br />
                <img
                  src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/d67c60d1-220a-40f8-a037-842213774cca.png"
                  style="max-height:200px;max-width:200px"
                />
              </li>
            </ol>
          </div>
        </div>
        <div class="card-footer" *ngIf="tipsShown">
          <h4>Tips & tricks:</h4>
          <ul style="list-style-type:disc;">
            <li>
              Don’t place textboxes inside stickers/banners/buttons (inside
              lockups is OK)
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/1d138e13-f92d-42a2-b8d5-1ba3c10374a6.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              Don’t label individual small part-objects, cluster them into
              obvious “wholes”
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/1024f06e-9e5a-4e91-8a79-7fe08209c3c1.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              Use your discretion when deciding if objects should be clustered
              or individually labelled
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/289a8042-3200-4011-a93f-160c8b2a75ed.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              For lockups, don’t rotate box orientation to match shape, capture
              the whole space
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/2b092f1b-f21d-4069-91ed-ae64b0a2369a.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              When deciding foreground and background, lockups usually sit about
              photographs
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/2aaa6451-23d4-40d0-a8ed-229267ddeb70.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              Photographs are always backgrounds (no label needed) not photo or
              product accents
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/148a1c2e-f672-4136-9771-38de100387d7.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              If products are held or worn, place box around entire
              human/product image
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/2cdbd53e-db3f-4f38-8932-732e9ff1603e.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              Do not label T&Cs or other very minor text or imagery
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/0bc2ff1f-2367-40b2-9c3c-fbd4507bf3ae.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              If an object looks like it is off-canvas, estimate the box around
              the whole object
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/1f42be29-88ab-438f-8062-2dec8cdbbfd2.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              Buttons can stretch across the entire ad
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/0f912e9d-d112-4e5d-ad6d-40c8ed5ad3a4.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              Buttons don’t need to have a graphic element, they can be just
              text too
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/1fb0b090-7efb-483f-a650-2016d276a1b0.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              If an object spans the whole ad, it is a background and can be
              left unlabelled
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/1a2ea326-1d55-4dab-b4d3-19ade3ddc9b3.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
            <li>
              Pay attention to the order of layers, work from the bottom up or
              use the layer buttons
              <br />
              <img
                src="https://pencil-staging-bucket.s3-ap-southeast-1.amazonaws.com/174f0893-4638-4270-9040-130b801a0ed4.png"
                style="max-height:200px;max-width:200px"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div>
      <p *ngIf="comparison">Golden Annotation</p>
      <canvas id="valAnnotationCanvas"></canvas>
    </div>
    <div>
      <p *ngIf="comparison">User Annotation</p>
      <canvas id="userAnnotationCanvas"></canvas>
    </div>
  </div>
</div>
