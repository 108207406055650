import * as _ from 'lodash';
import { Component, OnInit, HostListener, Input } from '@angular/core';
import { ImageService } from '../../../services/annotator/image.service';
import { TemplateService } from '../../../services/annotator/template.service';
import { MbrService } from '../../../services/annotator/mbr.service';

import 'fabric';
import { AnonymousSubject } from 'rxjs/internal/Subject';
declare const fabric: any;

@Component({
  selector: 'app-old-annotator',
  templateUrl: './old-annotator.component.html',
  styleUrls: ['./old-annotator.component.scss'],
})
export class OldAnnotatorComponent implements OnInit {
  @Input() taskId: number;
  private canvas: any;
  private props: any = {
    canvasFill: '#ffffff',
    canvasImage: '',
    id: null,
    opacity: null,
    fill: null,
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: null,
    TextDecoration: '',
  };

  private size: any = {
    width: 800,
    height: 800,
  };

  private objectTypes: Array<any>;
  private selected: any;
  private json: any;
  private question: any;
  private objectTypeCount: any;
  private backgroundObject: any;
  accuracy: number = 0.0;
  questionCount: number = 0;
  errorMessage: string = null;
  helpShown: boolean = false;
  tipsShown: boolean = false;
  comparison: boolean = false;
  points: Array<any> = [];
  annnotations: Array<any> = [];

  private userAnnotationCanvas: any;
  private userAnnotation: any;
  private valAnnotationCanvas: any;
  private valAnnotation: any;

  constructor(
    private imageService: ImageService,
    private templateService: TemplateService,
    private mbrService: MbrService
  ) {}

  ngOnInit() {
    // setup front side canvas
    this.canvas = new fabric.Canvas('canvas', {
      hoverCursor: 'pointer',
      selection: true,
      selectionBorderColor: 'blue',
    });

    this.userAnnotationCanvas = new fabric.Canvas('userAnnotationCanvas', {
      hoverCursor: 'pointer',
      selection: false,
    });

    this.valAnnotationCanvas = new fabric.Canvas('valAnnotationCanvas', {
      hoverCursor: 'pointer',
      selection: false,
    });

    this.objectTypes = this.templateService.listObjectTypes();
    this.getNextImage();
  }

  getNextImage() {
    this.canvas.clear();

    this.objectTypeCount = this.templateService.getObjectTypeInitialCount();

    this.errorMessage = null;
    this.imageService.getNextImage(this.taskId).subscribe(
      question => {
        this.question = question;
        if (!_.isNil(question.url)) {
          this.canvas.on({
            'object:moving': e => {},
            'object:modified': e => {},
            'object:selected': e => {
              const selectedObject = e.target;
              this.selected = selectedObject;
              selectedObject.hasRotatingPoint = true;
              selectedObject.transparentCorners = false;
            },
            'selection:cleared': e => {
              this.selected = null;
            },
            // 'mouse:down': options => {
            //   if (_.isNil(this.selected)) {
            //     var x = options.e.clientX - this.canvas._offset.left;
            //     var y = options.e.clientY - this.canvas._offset.top;

            //     var circle = new fabric.Circle({
            //       left: x,
            //       top: y,
            //       fill: 'red',
            //       originX: 'center',
            //       originY: 'center',
            //       hasControls: false,
            //       hasBorders: false,
            //       lockMovementX: true,
            //       lockMovementY: true,
            //       radius: 5,
            //       hoverCursor: 'default',
            //     });

            //     this.canvas.add(circle);
            //     this.points.push(new fabric.Point(x, y));
            //     const current_length = this.points.length;
            //     if (current_length > 1) {
            //       this.canvas.add(
            //         new fabric.Line(
            //           [
            //             this.points[current_length - 2].x,
            //             this.points[current_length - 2].y,
            //             this.points[current_length - 1].x,
            //             this.points[current_length - 1].y,
            //           ],
            //           {
            //             stroke: 'blue',
            //             hasControls: false,
            //             hasBorders: false,
            //             lockMovementX: true,
            //             lockMovementY: true,
            //             hoverCursor: 'default',
            //           }
            //         )
            //       );
            //     }
            //   }
            // },
          });

          this.canvas.setWidth(this.size.width);
          this.canvas.setHeight(this.size.height);
          this.addImageOnCanvas(question.url);
          this.imageService.getAccuracy(this.taskId).subscribe(
            a => {
              this.accuracy = Math.floor(a.accuracy);
              this.userAnnotation = a.userAnnotation;
              this.valAnnotation = a.validationAnnotation;
              this.imageService.getQuestionCount(this.taskId).subscribe(c => {
                this.questionCount = c.count;
              });
            },
            e => console.log(e)
          );
        } else {
          this.errorMessage = question.message;
        }
      },
      error => {
        this.errorMessage = error.error.message;
      }
    );
  }

  saveImage() {
    this.rasterizeJSON();
    if (this.templateService.isTemplateJsonValid(this.json)) {
      this.imageService
        .saveImage({
          id: this.question.id,
          annotation: this.json,
          is_validation: this.question.is_validation,
        })
        .subscribe(() => {
          this.getNextImage();
        });
    } else {
      this.getNextImage();
    }
  }

  /*------------------------Block elements------------------------*/

  // Block "Size"

  changeSize(event: any) {
    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);
  }

  // Block "Upload Image"

  addImageOnCanvas(url) {
    if (url) {
      fabric.Image.fromURL(url, image => {
        let new_width = image.width;
        let new_height = image.height;
        if (image.width >= image.height && image.width > 800) {
          new_width = 800;
          new_height = Math.floor((image.height * 800) / image.width);
        } else if (image.height >= image.width && image.height > 800) {
          new_height = 800;
          new_width = Math.floor((image.width * 800) / image.height);
        }
        image.set({
          left: new_width / 2,
          top: new_height / 2,
          angle: 0,
          padding: 0,
          cornersize: 0,
          hasRotatingPoint: false,
          originX: 'center',
          originY: 'center',
        });
        // if (image.width > 800 || image.height > 800) {
        image.scaleToWidth(new_width);
        image.scaleToHeight(new_height);

        this.canvas.setWidth(new_width);
        this.canvas.setHeight(new_height);
        // }

        this.extend(image, this.randomId('background'));
        this.canvas.add(image);
        this.backgroundObject = image;

        this.canvas.setBackgroundImage(
          image,
          this.canvas.renderAll.bind(this.canvas)
        );
        image.selectable = false;
        // this.selectItemAfterAdded(image);
      });
    }
  }

  drawRectangles(canvas, templateJson): any {
    canvas.setWidth(templateJson._canvasDimensions.width);
    canvas.setHeight(templateJson._canvasDimensions.height);
    fabric.Image.fromURL(templateJson._originalImgUrl, image => {
      image.set({
        left: templateJson._canvasDimensions.width / 2,
        top: templateJson._canvasDimensions.height / 2,
        angle: 0,
        padding: 0,
        cornersize: 0,
        hasRotatingPoint: false,
        originX: 'center',
        originY: 'center',
      });
      image.scaleToWidth(templateJson._canvasDimensions.width);
      image.scaleToHeight(templateJson._canvasDimensions.height);
      canvas.add(image);
      canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas));
      image.selectable = false;
      templateJson.objects.map(o => {
        const objectType = o._key.split('_placeholder')[0];
        const strokeFill = this.templateService.getObjectFill(objectType);
        if (strokeFill) {
          let add: any;
          const max = (add = new fabric.Rect({
            width: o.width,
            height: o.height,
            left: o.left,
            top: o.top,
            angle: o.angle,
            stroke: strokeFill.stroke,
            fill: strokeFill.fill,
            originX: 'center',
            originY: 'center',
          }));
          canvas.add(add);
        }
      });
      canvas.renderAll();
    });
  }

  removeAllObjects() {
    this.canvas.clear();
    this.canvas.setWidth(this.size.width);
    this.canvas.setHeight(this.size.height);
    this.addImageOnCanvas(this.question.url);
    this.imageService.getAccuracy(this.taskId).subscribe(
      a => {
        console.log(JSON.stringify(a));
        this.accuracy = Math.floor(a.accuracy);
        if (a.userAnnotation) {
          this.drawRectangles(this.userAnnotationCanvas, a.userAnnotation);
        }
        if (a.validationAnnotation) {
          this.drawRectangles(this.valAnnotationCanvas, a.validationAnnotation);
        }
        this.imageService.getQuestionCount(this.taskId).subscribe(c => {
          this.questionCount = Math.floor(c.count);
        });
      },
      e => console.log(e)
    );
  }

  // Block "Add figure"

  addFigure(figure) {
    let rectangle: any;
    let cx: number, cy: number, width: number, height: number, angle: number;

    const strokeFill = this.templateService.getObjectFill(figure);
    const current_length = this.points.length;

    if (current_length > 3) {
      this.mbrService.getBoundingBox(this.points).subscribe(
        result => {
          console.log(`result = ${JSON.stringify(result)}`);

          rectangle = new fabric.Rect({
            width: result.width,
            height: result.height,
            left: result.left,
            top: result.top,
            angle: result.angle,
            stroke: strokeFill.stroke,
            fill: strokeFill.fill,
            originX: 'center',
            originY: 'center',
          });

          this.clearPoints();

          this.extend(rectangle, this.randomId(figure));
          this.canvas.add(rectangle);
          this.selectItemAfterAdded(rectangle);
        },
        e => {
          const min_x = _.min(_.map(this.points, p => p.x));
          const min_y = _.min(_.map(this.points, p => p.y));
          const max_x = _.max(_.map(this.points, p => p.x));
          const max_y = _.max(_.map(this.points, p => p.y));
          width = max_x - min_x;
          height = max_y - min_y;
          cx = (min_x + max_x) / 2;
          cy = (min_y + max_y) / 2;
          // angle =
          //   -90 +
          //   (Math.atan((this.points[0].y - cy) / (this.points[0].x - cx)) *
          //     180) /
          //     Math.PI;
          angle = 0;

          rectangle = new fabric.Rect({
            width: width,
            height: height,
            left: cx,
            top: cy,
            angle: angle,
            stroke: strokeFill.stroke,
            fill: strokeFill.fill,
            originX: 'center',
            originY: 'center',
          });

          this.clearPoints();

          this.extend(rectangle, this.randomId(figure));
          this.canvas.add(rectangle);
          this.selectItemAfterAdded(rectangle);
        }
      );
    } else {
      width = Math.max(Math.floor(this.canvas.width / 4), 25);
      height = Math.max(Math.floor(this.canvas.height / 4), 25);
      cx = Math.floor(Math.random() * (width / 2)) + width / 2;
      cy = Math.floor(Math.random() * (height / 2)) + height / 2;
      angle = 0;

      rectangle = new fabric.Rect({
        width: width,
        height: height,
        left: cx,
        top: cy,
        angle: angle,
        stroke: strokeFill.stroke,
        fill: strokeFill.fill,
        originX: 'center',
        originY: 'center',
      });

      this.clearPoints();

      this.extend(rectangle, this.randomId(figure));
      this.canvas.add(rectangle);
      this.selectItemAfterAdded(rectangle);
    }
  }

  clearPoints() {
    this.points.length = 0;
    this.removePoints();
  }

  /*Canvas*/
  selectItemAfterAdded(obj) {
    this.canvas.setActiveObject(obj);
  }

  extend(obj, id) {
    obj.toObject = (function(toObject) {
      return function() {
        return fabric.util.object.extend(toObject.call(this), {
          id: id,
        });
      };
    })(obj.toObject);
  }

  randomId(objectType: string) {
    const id = this.objectTypeCount[objectType];
    this.objectTypeCount[objectType] += 1;
    return id;
  }

  /*------------------------Global actions for element------------------------*/

  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) return '';

    return object.getSelectionStyles && object.isEditing
      ? object.getSelectionStyles()[styleName] || ''
      : object[styleName] || '';
  }

  setActiveStyle(styleName, value, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) return;

    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;
      object.setSelectionStyles(style);
      object.setCoords();
    } else {
      object.set(styleName, value);
    }

    object.setCoords();
    this.canvas.renderAll();
  }

  getActiveProp(name) {
    const object = this.canvas.getActiveObject();
    if (!object) return '';

    return object[name] || '';
  }

  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject();
    if (!object) return;
    object.set(name, value).setCoords();
    this.canvas.renderAll();
  }

  clone(identical) {
    const activeObject = this.canvas.getActiveObject(),
      activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      let clone;
      switch (activeObject.type) {
        case 'rect':
          clone = new fabric.Rect(activeObject.toObject());
          break;
        case 'circle':
          clone = new fabric.Circle(activeObject.toObject());
          break;
        case 'triangle':
          clone = new fabric.Triangle(activeObject.toObject());
          break;
        case 'i-text':
          clone = new fabric.IText('', activeObject.toObject());
          break;
        case 'image':
          clone = fabric.util.object.clone(activeObject);
          break;
      }
      if (clone) {
        clone.set({ left: clone.width / 2, top: clone.height / 2 });
        if (identical === true) {
          this.extend(clone, activeObject.toObject().id);
        } else {
          const figure = this.templateService.getObjectType(
            activeObject.toObject().type,
            activeObject.toObject().fill
          );
          this.extend(clone, this.randomId(figure));
        }
        this.canvas.add(clone);
        this.selectItemAfterAdded(clone);
      }
    }
  }

  getId() {
    this.props.id = this.canvas.getActiveObject().toObject().id;
  }

  getOpacity() {
    this.props.opacity = this.getActiveStyle('opacity', null) * 100;
  }
  /*System*/

  removeSelected() {
    const activeObject = this.canvas.getActiveObject(),
      activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      this.canvas.remove(activeObject);
    } else if (activeGroup) {
      const objectsInGroup = activeGroup.getObjects();
      this.canvas.discardActiveGroup();
      const self = this;
      objectsInGroup.forEach(function(object) {
        self.canvas.remove(object);
      });
    }
  }

  removePoints() {
    this.canvas.getObjects().forEach(o => {
      const objectType = this.templateService.getObjectType(o.type, o.fill);
      if (_.isNil(objectType)) {
        this.canvas.remove(o);
      }
    });
  }

  bringToFront() {
    const activeObject = this.canvas.getActiveObject(),
      activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      // activeObject.bringToFront();
      // activeObject.opacity = 1;
      activeObject.bringForward();
    } else if (activeGroup) {
      const objectsInGroup = activeGroup.getObjects();
      this.canvas.discardActiveGroup();
      objectsInGroup.forEach(object => {
        object.bringForward();
      });
    }
  }

  sendToBack() {
    const activeObject = this.canvas.getActiveObject(),
      activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      //   activeObject.sendToBack();
      console.log('1');
      activeObject.sendBackwards(true);
      this.backgroundObject.sendToBack();
      // activeObject.opacity = 1;
    } else if (activeGroup) {
      const objectsInGroup = activeGroup.getObjects();
      this.canvas.discardActiveGroup();
      objectsInGroup.forEach(object => {
        object.sendBackwards();
        this.backgroundObject.sendToBack();
      });
    }
  }

  confirmClear() {
    if (confirm('Are you sure?')) {
      this.canvas.clear();
    }
  }

  rasterize() {
    if (!fabric.Canvas.supports('toDataURL')) {
      alert(
        // tslint:disable-next-line: quotemark
        "This browser doesn't provide means to serialize canvas to an image"
      );
    } else {
      const image = new Image();
      image.src = this.canvas.toDataURL('png');
      const w = window.open('');
      w.document.write(image.outerHTML);
    }
  }

  rasterizeSVG() {
    const w = window.open('');
    w.document.write(this.canvas.toSVG());
  }

  saveCanvasToJSON() {
    const json = JSON.stringify(this.canvas);
    localStorage.setItem('Kanvas', json);
  }

  loadCanvasFromJSON() {
    const CANVAS = localStorage.getItem('Kanvas');

    // and load everything from the same json
    this.canvas.loadFromJSON(CANVAS, () => {
      // making sure to render canvas at the end
      this.canvas.renderAll();
    });
  }

  rasterizeJSON() {
    this.json = JSON.stringify(this.canvas, null, 2);
    let jsonObject = JSON.parse(this.json);
    jsonObject = this.templateService.updateTemplateJson(
      this.question.url,
      jsonObject,
      this.canvas.width,
      this.canvas.height
    );
    this.json = JSON.stringify(jsonObject, null, 2);
  }

  @HostListener('window:keydown', ['$event'])
  canvasKeyboardEvent(event: KeyboardEvent) {
    if (
      (event.ctrlKey || event.metaKey) &&
      !event.shiftKey &&
      event.keyCode === 86
    ) {
      this.clone(false);
    }

    if (
      (event.ctrlKey || event.metaKey) &&
      event.shiftKey &&
      event.keyCode === 86
    ) {
      this.clone(true);
    }

    if (!event.shiftKey && event.keyCode === 8) {
      this.removeSelected();
    }

    if (event.shiftKey && event.keyCode === 8) {
      this.removeAllObjects();
    }

    if (event.shiftKey && event.keyCode === 38) {
      this.bringToFront();
    }

    if (event.shiftKey && event.keyCode === 40) {
      this.sendToBack();
    }

    event.stopPropagation();
  }

  showHelp() {
    this.helpShown = true;
  }

  hideHelp() {
    this.helpShown = false;
  }

  showTips() {
    this.tipsShown = true;
  }

  hideTips() {
    this.tipsShown = false;
  }

  showComparison() {
    this.comparison = true;
    if (this.userAnnotation) {
      this.drawRectangles(this.userAnnotationCanvas, this.userAnnotation);
    }
    if (this.valAnnotation) {
      this.drawRectangles(this.valAnnotationCanvas, this.valAnnotation);
    }
  }

  hideComparison() {
    this.comparison = false;
    this.userAnnotationCanvas.clear();
    this.valAnnotationCanvas.clear();
  }

  // returns true iff the line from (a,b)->(c,d) intersects with (p,q)->(r,s)
  intersects(a, b, c, d, p, q, r, s) {
    var det, gamma, lambda;
    det = (c - a) * (s - q) - (r - p) * (d - b);
    if (det === 0) {
      return false;
    } else {
      lambda = ((s - q) * (r - a) + (p - r) * (s - b)) / det;
      gamma = ((b - d) * (r - a) + (c - a) * (s - b)) / det;
      return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
    }
  }
}
