/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-profiling-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@clr/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i6 from "./task-profiling-modal.component";
import * as i7 from "@angular/router";
import * as i8 from "../tasks.service";
var styles_TaskProfilingModalComponent = [i0.styles];
var RenderType_TaskProfilingModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskProfilingModalComponent, data: {} });
export { RenderType_TaskProfilingModalComponent as RenderType_TaskProfilingModalComponent };
function View_TaskProfilingModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.value; _ck(_v, 3, 0, currVal_2); }); }
function View_TaskProfilingModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i3.ClrLabel, [[2, i3.ɵq], [2, i3.ɵr], [2, i3.ɵs], i1.Renderer2, i1.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "select"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "select", [["name", "question.id"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.model[_v.parent.context.$implicit.id] = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(8, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskProfilingModalComponent_4)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "single-", _v.parent.context.index, ""); _ck(_v, 2, 0, currVal_1); var currVal_11 = "question.id"; var currVal_12 = _co.model[_v.parent.context.$implicit.id]; _ck(_v, 8, 0, currVal_11, currVal_12); var currVal_13 = _v.parent.context.$implicit.options; _ck(_v, 12, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).forAttr; _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.description; _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "single-", _v.parent.context.index, ""); var currVal_4 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 10).ngClassValid; var currVal_9 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
function View_TaskProfilingModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_s, [i1.ElementRef, i1.Renderer2, [2, i2.SelectMultipleControlValueAccessor]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.value; _ck(_v, 3, 0, currVal_2); }); }
function View_TaskProfilingModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [], [[1, "for", 0]], null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i3.ClrLabel, [[2, i3.ɵq], [2, i3.ɵr], [2, i3.ɵs], i1.Renderer2, i1.ElementRef], { forAttr: [0, "forAttr"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "select multiple"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "select", [["multiple", ""], ["name", "question.id"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onChange($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.model[_v.parent.context.$implicit.id] = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.SelectMultipleControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectMultipleControlValueAccessor]), i1.ɵdid(8, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskProfilingModalComponent_6)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "multi-", _v.parent.context.index, ""); _ck(_v, 2, 0, currVal_1); var currVal_11 = "question.id"; var currVal_12 = _co.model[_v.parent.context.$implicit.id]; _ck(_v, 8, 0, currVal_11, currVal_12); var currVal_13 = _v.parent.context.$implicit.options; _ck(_v, 12, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).forAttr; _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.description; _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "multi-", _v.parent.context.index, ""); var currVal_4 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 10).ngClassValid; var currVal_9 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
function View_TaskProfilingModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "form-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskProfilingModalComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskProfilingModalComponent_5)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.questionType.id === _co.questionTypes.single); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.questionType.id === _co.questionTypes.multi); _ck(_v, 4, 0, currVal_1); }, null); }
function View_TaskProfilingModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "clr-modal", [], [[2, "open", null]], [[null, "clrModalOpenChange"], ["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).close() !== false);
        ad = (pd_0 && ad);
    } if (("clrModalOpenChange" === en)) {
        var pd_1 = ((_co.openModal = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_ClrModal_0, i5.RenderType_ClrModal)), i1.ɵprd(8704, null, i3.ɵdl, i3.ɵdl, [i4.DOCUMENT]), i1.ɵprd(1024, null, i3.ɵdh, i3.ɵdi, []), i1.ɵdid(3, 704512, null, 0, i3.ClrModal, [i3.ɵdl, i3.ClrCommonStrings, i3.ɵdh], { _open: [0, "_open"], closable: [1, "closable"], size: [2, "size"] }, { _openChanged: "clrModalOpenChange" }), (_l()(), i1.ɵeld(4, 0, null, 1, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please answer the following questions:"])), (_l()(), i1.ɵeld(6, 0, null, 2, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(9, 4210688, [["taskProfileForm", 4]], 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(11, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskProfilingModalComponent_2)), i1.ɵdid(13, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, 3, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSkip() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Skip"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Submit "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.openModal; var currVal_2 = false; var currVal_3 = "lg"; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_11 = _co.taskProfileQuestions; _ck(_v, 13, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3)._open; _ck(_v, 0, 0, currVal_0); var currVal_4 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 11).ngClassValid; var currVal_9 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_TaskProfilingModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskProfilingModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openModal; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TaskProfilingModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-profiling-modal", [], null, null, null, View_TaskProfilingModalComponent_0, RenderType_TaskProfilingModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.TaskProfilingModalComponent, [i7.Router, i8.TasksService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskProfilingModalComponentNgFactory = i1.ɵccf("app-task-profiling-modal", i6.TaskProfilingModalComponent, View_TaskProfilingModalComponent_Host_0, { openModal: "openModal", task: "task", taskProfileQuestions: "taskProfileQuestions" }, { closeModal: "closeModal" }, []);
export { TaskProfilingModalComponentNgFactory as TaskProfilingModalComponentNgFactory };
