import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { AlertService } from '../../services/alert/alert.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  public user: any;

  constructor(
    private router: Router,
    private auth: AuthService,
    private alertService: AlertService
  ) {}

  // Check that user role is authenticated
  private checkLoggedIn(): Observable<any> {
    return this.auth.isAuthenticated().pipe(
      mergeMap(({ loggedIn, user }) => {
        if (!loggedIn.value) {
          this.alertService.contentAlert = {
            type: 'warning',
            text: 'Please log in to access the page.',
            closable: false,
          };
          this.router.navigate(['/login']);
        }

        return of(user);
      })
    );
  }

  // Check that user role meets minimum role on route
  private checkRole(user: any, route: any): Observable<boolean> {
    if (route.data && route.data.role) {
      if (user.role < route.data.role) {
        this.alertService.contentAlert = {
          type: 'warning',
          text: 'You are not allowed to access this page.',
          closable: false,
        };
        this.router.navigate(['/login']);
      }
    }

    return of(true);
  }

  // Guard for component activation
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkLoggedIn().pipe(
      mergeMap(({ value }) => {
        return this.checkRole(value, route);
      })
    );
  }

  // Guard for child component activation
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }

  // Guard for lazy loading components
  canLoad(route: Route): Observable<boolean> {
    return this.checkLoggedIn().pipe(
      mergeMap(({ value }) => {
        return this.checkRole(value, route);
      })
    );
  }
}
