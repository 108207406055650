<clr-modal
  *ngIf="openModal"
  [(clrModalOpen)]="openModal"
  [clrModalClosable]="false"
  [clrModalSize]="'lg'"
>
  <h3 class="modal-title">{{ task.description }}</h3>
  <div class="modal-body">
    <div Markdown>{{ task.instructions }}</div>
  </div>
  <div class="modal-footer">
    <div class="modal-actions clr-row clr-justify-content-end">
      <div class="clr-col-xs-4">
        <button class="btn btn-outline" (click)="onClose()">Go Back</button>
      </div>
      <div *ngIf="(auth.isAdmin$ | async)" class="clr-col-xs-4">
        <button
          [disabled]="!task.validation"
          class="btn btn-outline"
          (click)="onValidate()"
        >
          Validate
        </button>
      </div>
      <div class="clr-col-xs-4">
        <button
          type="button"
          class="btn btn-primary"
          (click)="onProceed()"
          [disabled]="!isValidated || task.progress === 100"
        >
          Proceed
        </button>
      </div>
    </div>
  </div>
</clr-modal>

<app-task-profiling-modal
  [openModal]="openProfileModal"
  [taskProfileQuestions]="taskProfileQuestions"
  [task]="task"
  (closeModal)="onProfileModalClose($event)"
></app-task-profiling-modal>
