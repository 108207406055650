import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pipes
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SafeHtmlPipe, EnumToArrayPipe],
  exports: [SafeHtmlPipe, EnumToArrayPipe],
  providers: [],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
