import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from './../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';
import { AlertService } from '../alert/alert.service';

declare const window: any;
declare const FB: any;

@Injectable()
export class FacebookService {
  constructor(
    private router: Router,
    private auth: AuthService,
    private userService: UserService,
    private alertService: AlertService,
    private zone: NgZone
  ) {}

  onLogin() {
    window.fbCallback = () => {
      return FB.getLoginStatus(response => {
        if (response.authResponse) {
          return FB.api('/me?fields=id,email,name', userInfo => {
            // Access token returned by the IdP. We verify this on the
            // backend and if valid, we create/update the user
            const { accessToken } = response.authResponse;

            return this.userService
              .oauthSignin({
                provider: 'facebook',
                token: accessToken,
                email: userInfo.email,
                name: userInfo.name,
                facebookId: userInfo.id,
              })
              .subscribe(
                ({ data }) => {
                  const { token } = data.oauthSignin;
                  localStorage.setItem('pencil-hit-token', token);

                  this.zone.run(() => {
                    this.router.navigate(['/tasks']);
                  });
                },
                error => {
                  const errorMsg = error.message.split(':')[1].trim();
                  this.alertService.contentAlert = {
                    type: 'warning',
                    text: 'Please log in to access the page.',
                    closable: false,
                  };

                  if (errorMsg === 'UserNotWhitelisted') {
                    this.alertService.contentAlert.text =
                      'Your email is invalid. Please drop us an email at <a href="mailto:contact-us@trytasks.com" target="_top">contact-us@trytasks.com</a>';
                  }

                  this.zone.run(() => {
                    this.router.navigate(['/login']);
                  });
                }
              );
          });
        }
      });
    };
  }
}
