import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as _ from 'lodash';
import { MarkdownService } from 'angular2-markdown';
import { TasksService } from './tasks.service';
import { AlertService } from '../../services/alert/alert.service';
import { AuthService } from '../../services/auth/auth.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { SettingsService } from '../../services/settings/settings.service';
import { ImageService } from '../../services/annotator/image.service';
import { TaskTypes } from '../../enums/taskTypes.enum';
var TasksComponent = /** @class */ (function () {
    function TasksComponent(tasksService, mdService, activatedRoute, router, settingsService, alertService, appSpinner, auth, imageService) {
        var _this = this;
        this.tasksService = tasksService;
        this.mdService = mdService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.settingsService = settingsService;
        this.alertService = alertService;
        this.appSpinner = appSpinner;
        this.auth = auth;
        this.imageService = imageService;
        this.subscribe = true;
        this.isValidated = false;
        this.loading = true;
        this.openModal = false;
        this.router.events.subscribe(function () {
            setTimeout(function () {
                _this.alertService.contentAlert = null;
            }, 2000);
        });
    }
    TasksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.taskTypes = TaskTypes;
        this.appSpinner.showSpinner();
        return this.tasksService
            .getTasks(this.auth.isAdmin$.value)
            .pipe(takeWhile(function () { return _this.subscribe; }), map(function (_a) {
            var data = _a.data;
            var tasksForUser = _.map(data.allTasks, function (t) { return (tslib_1.__assign({ progress: Math.min((t.currentLabelCount / t.maxLabels) * 100, 100) }, t)); });
            return of(tasksForUser);
        }), catchError(function (error) {
            console.log(error);
            // Treat as no tasks found
            return of([]);
        }))
            .subscribe(function (_a) {
            var value = _a.value;
            if (_.isUndefined(value)) {
                _this.tasks = [];
                return null;
            }
            _this.tasks = value;
            //NOTE: Adding new annotator task here
            _this.imageService.getTasks().subscribe(function (tasks) {
                _this.tasks = _.concat(tasks, _this.tasks);
                _.each(_this.tasks, function (t) {
                    var html = _this.mdService.compile(_this.prepareMd(t.instructions));
                    t.shortDesc = _this.obtainShortDesc(html);
                });
                _this.displayedTasks = _this.tasks;
                _this.appSpinner.hideSpinner();
            }, function (error) {
                console.log("No annotator task");
                _this.displayedTasks = _this.tasks;
                _this.appSpinner.hideSpinner();
            });
        }, function (error) { return console.log(error); });
    };
    TasksComponent.prototype.ngOnDestroy = function () {
        if (this.subscribe) {
            this.subscribe = false;
        }
    };
    TasksComponent.prototype.showTaskType = function (taskType) {
        var _this = this;
        if (!taskType) {
            this.displayedTasks = this.tasks;
        }
        else {
            this.displayedTasks = _.filter(this.tasks, function (t) {
                return t.taskType.id === _this.taskTypes[taskType];
            });
        }
    };
    TasksComponent.prototype.onTaskSelect = function (taskId) {
        var _this = this;
        this.appSpinner.showSpinner();
        this.selectedTask = _.find(this.tasks, { id: taskId });
        this.tasksService.getValidationCount(this.selectedTask.id).subscribe(function (_a) {
            var data = _a.data;
            var validationCount = data.validationCount;
            var minValidation = parseInt(_this.settingsService.findValue('MINIMUM_VALIDATION_SET'), 10);
            // If task does not require validation, set this to true
            _this.isValidated =
                validationCount >= minValidation || !_this.selectedTask.validation;
            _this.openModal = true;
        }, function (error) {
            console.log(error);
            _this.appSpinner.hideSpinner();
            _this.alertService.contentAlert = {
                type: 'danger',
                text: "An error has occured. If this error persists, please drop us an\n          email at <a href=\"mailto:contact-us@trytasks.com\" target=\"_top\">contact-us@trytasks.com</a>.",
                closable: false,
            };
            setTimeout(function () {
                _this.alertService.contentAlert = null;
            }, 2000);
        });
    };
    TasksComponent.prototype.onModalClose = function (closed) {
        this.openModal = false;
        this.appSpinner.hideSpinner();
    };
    TasksComponent.prototype.obtainShortDesc = function (compiled) {
        // lazy match the first p tag
        var shortDesc = /<p>(.*?)<\/p>/.exec(compiled);
        // return first capturing group
        return shortDesc && shortDesc[1];
    };
    /**
     * Extracted from angular2-markdown. Used to parse a raw markdown string.
     * @see https://github.com/dimpu/ngx-md/blob/master/src/markdown/markdown.component.ts#L102
     */
    TasksComponent.prototype.prepareMd = function (raw) {
        var _this = this;
        if (!raw) {
            return '';
        }
        var isCodeBlock = false;
        return raw
            .split('\n')
            .map(function (line) {
            if (_this.trimLeft(line).substring(0, 3) === '```') {
                isCodeBlock = !isCodeBlock;
            }
            return isCodeBlock ? line : line.trim();
        })
            .join('\n');
    };
    TasksComponent.prototype.trimLeft = function (line) {
        return line.replace(/^\s+|\s+$/g, '');
    };
    return TasksComponent;
}());
export { TasksComponent };
