<div [hidden]="appSpinner.loading$ | async" *ngIf="tasks">
  <div class="clr-row clr-justify-content-end view-filter">
    <clr-dropdown>
      <button type="button" class="btn btn-outline-primary" clrDropdownTrigger>
        Filter
        <clr-icon shape="caret down"></clr-icon>
      </button>
      <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
        <label class="dropdown-header">Filter by</label>
        <button type="button" (click)="showTaskType(null)" clrDropdownItem>all</button>
        <button *ngFor="let taskType of taskTypes | enumToArray" type="button" (click)="showTaskType(taskType)"
          clrDropdownItem>{{taskType}}</button>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>

  <div class="clr-row clr-justify-content-center">
    <div *ngIf="!tasks.length">
      <div class="hero">¯\_(ツ)_/¯</div>
      <h3>There are no available tasks at the moment. Stay tuned for more!</h3>
    </div>

    <div *ngFor="let task of displayedTasks; let i = index" class="clr-col-lg-4 clr-col-md-12 clr-col-sm-12 clr-col-xs-12" [hidden]="!task.enabled && !(auth.isAdmin$ | async)">
      <a (click)="onTaskSelect(task.id)" class="card clickable">
        <div class="card-block">
          <div role="tooltip" aria-haspopup="true" class="progress top tooltip tooltip-sm">
            <progress value="{{task.progress}}" max="100"></progress>
            <span class="tooltip-content">{{task.currentLabelCount}} of {{task.maxLabels}} judgements</span>
          </div>
          <h4 class="card-title" [ngClass]="{'disabled': !task.enabled}">{{ task.enabled ? '' : '(Disabled)' }} {{
            task.description
            }}
          </h4>
          <p class="card-text" [innerHTML]="task.shortDesc | safeHtml"></p>
        </div>
        <div class="card-footer">
          <span class="label">{{ taskTypes[task.taskType.id] }}</span>
          <span class="label">payout<span class="badge">${{ task.payoutPerQuestion }}</span></span>
          <span class="label">requires<span class="badge">{{ task.minAccuracy * 100 }}%</span></span>
        </div>
      </a>
    </div>
  </div>
</div>

<app-task-modal [openModal]="openModal" [task]="selectedTask" [isValidated]="isValidated" (closeModal)="onModalClose($event)"></app-task-modal>
