export enum TaskQuestionTypes {
  segmentation = 3,
  labelling = 4,
  highlighting = 5,
  objectDetection = 6,
  pairValidation = 7,
  pairLabelling = 8
}

export enum ProfileQuestionTypes {
  single = 1,
  multi = 2,
}
