<div class="login-wrapper">
  <form class="login" [formGroup]="signupForm" (ngSubmit)="onSubmitSignup(signupForm.value)">
    <label class="title">
      <h3 class="welcome">Sign up for</h3>
      Pencil
      <h5 class="hint">Please enter your details</h5>
    </label>
    <div class="login-group">
      <input class="username" type="text" id="email" placeholder="Email">
      <input class="password" type="password" id="password" placeholder="Password">
      <input class="password" type="password" id="confirm-password" placeholder="Confirm Password">
      <div *ngIf="!signupForm.valid" class="error">
        Invalid email or password
      </div>
      <button type="submit" class="btn btn-primary">SUBMIT</button>
    </div>
  </form>
</div>
