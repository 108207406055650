import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, from } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { environment } from './../../../environments/environment';
import { Roles } from '../../enums/roles.enum';
@Injectable()
export class AuthService {
  public loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public user$: BehaviorSubject<any> = new BehaviorSubject<any>({
    role: Roles.public,
  });
  public isRegistered$: BehaviorSubject<any> = new BehaviorSubject<boolean>(
    false
  );
  public isAdmin$: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  public isSuperAdmin$: BehaviorSubject<any> = new BehaviorSubject<boolean>(
    false
  );

  /** Get authenticated state */
  public isAuthenticated(): Observable<any> {
    // Check for JWT and verify unexpired. If valid, do not need to
    // make a refresh token trip
    const token = localStorage.getItem('pencil-hit-token');

    if (token) {
      const now = Math.round(Date.now() / 1000);
      const claims = token.split('.')[1];
      const user = JSON.parse(atob(claims));

      if (now < user.exp) {
        this.loggedIn$.next(true);
        this.user$.next(user);

        // Roles
        this.isRegistered$.next(this.user$.value.role >= Roles.registered_user);
        this.isAdmin$.next(this.user$.value.role >= Roles.admin);
        this.isSuperAdmin$.next(this.user$.value.role >= Roles.super_admin);

        return of({
          loggedIn: this.loggedIn$,
          user: this.user$,
        });
      }
    }

    // FIXME: Sign out for now. Handle refresh token in future
    this.loggedIn$.next(false);
    this.user$.next({ role: Roles.public });

    return of({
      loggedIn: this.loggedIn$,
      user: this.user$,
    });
  }
}
