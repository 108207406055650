<div class="login-wrapper clr-justify-content-center">
  <span *ngIf="loading" class="spinner spinner-lg"></span>

  <form [hidden]="loading" class="login" [formGroup]="loginForm" (ngSubmit)="onEmailLogin(loginForm.value)">
    <div class="clr-row clr-justify-content-center">
      <img class="logo-img" src="../../assets/tasks_logo_white_transparent_1.png" alt="Try Tasks">
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-xs-12">
        <!-- <div class="clr-row clr-justify-content-center">
          <div class="fb-login-button" data-max-rows="1" data-size="large" data-button-type="login_with" data-show-faces="false" data-auto-logout-link="false"
            data-use-continue-as="true" data-onlogin="fbCallback()" data-scope="public_profile,email"></div>
        </div> -->
      </div>
    </div>

    <div class="clr-row clr-justify-content-center pb-2">
      <div class="clr-col-xs-12">
        <div (click)="onSocialLogin()" class="clr-row clr-justify-content-center">
          <div id="g-signin2"></div>
        </div>
      </div>
    </div>

    <!--
        <div class="login-group">
          <input class="username" type="text" id="login_email" placeholder="Email">
          <input class="password" type="password" id="login_password" placeholder="Password">
          <div class="checkbox">
            <input type="checkbox" id="rememberme">
            <label for="rememberme">
              Remember me
            </label>
          </div>
          <div *ngIf="!loginForm.valid" class="error">
            Invalid email or password
          </div>
          <button type="submit" class="btn btn-primary">NEXT</button>
          <a href="#" [routerLink]="['/signup']" class="signup">Sign up</a>
        </div>
      -->
  </form>
</div>
