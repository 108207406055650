import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';

import { TasksService } from '../../tasks/tasks.service';
import { ProfileQuestionTypes } from '../../../enums/questionTypes.enum';

@Component({
  selector: 'app-task-profiling-modal',
  templateUrl: './task-profiling-modal.component.html',
  styleUrls: ['./task-profiling-modal.component.scss'],
})
export class TaskProfilingModalComponent implements OnInit {
  @Input() openModal: boolean;
  @Input() task: any;
  @Input() taskProfileQuestions: any;
  @Output() closeModal = new EventEmitter<boolean>();
  questionTypes: any;
  model: any = {};

  constructor(private router: Router, private tasksService: TasksService) {}

  ngOnInit() {
    this.questionTypes = ProfileQuestionTypes;
  }

  onSubmit() {
    const promises = _.transform(
      this.taskProfileQuestions,
      (acc, q) => {
        const answer = this.model[q.id];

        if (answer) {
          // Multiselect
          if (_.isArray(answer)) {
            const p = _.map(answer, i => {
              return this.tasksService.updateUserProfile(this.task.id, q.id, i);
            });

            return acc.push(...p);
          }

          // Single select
          return acc.push(
            this.tasksService.updateUserProfile(this.task.id, q.id, answer)
          );
        }

        // Null
        return acc.push(
          this.tasksService.updateUserProfile(this.task.id, q.id, null)
        );
      },
      []
    );

    return forkJoin(...promises).subscribe(
      () => {
        // reset openModal property on the parent
        this.closeModal.emit(true);
        this.openModal = false;
        this.router.navigate([`/task/${this.task.id}`]);
      },
      error => console.log(error)
    );
  }

  onSkip() {
    const promises = _.map(this.taskProfileQuestions, q => {
      return this.tasksService.updateUserProfile(this.task.id, q.id, null);
    });

    return forkJoin(...promises).subscribe(
      () => {
        // reset openModal property on the parent
        this.closeModal.emit(true);
        this.openModal = false;
        this.router.navigate([`/task/${this.task.id}`]);
      },
      error => console.log(error)
    );
  }
}
