import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

import * as _ from 'lodash';

@Injectable()
export class SettingsService {
  cache: any = {};
  cache_expiry: number;

  constructor(private apollo: Apollo) {}

  /**
   * Init settings cache
   */
  init() {
    // Set new cache expiry (5min)
    this.cache_expiry = Date.now() + 300 * 1000;

    const query = gql`
      query {
        settings {
          key
          value
        }
      }
    `;

    return this.apollo
      .query<any>({
        query,
        fetchPolicy: 'network-only',
      })
      .subscribe(({ data }) => {
        const settings = data.settings;

        // Populate the cache
        _.each(settings, s => {
          this.cache[s.key] = s.value;
        });
      });
  }

  /**
   * Find a setting
   */
  findValue(key) {
    const cacheStale = this.cache_expiry && Date.now() > this.cache_expiry;

    // Cache miss
    if (_.isEmpty(this.cache) || cacheStale) {
      // Refresh the cache for next invocation
      this.init();
    }

    // Cache hit
    return this.cache[key];
  }
}
