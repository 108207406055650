export const environment = {
  production: true,
  apiUrl: 'https://gafr7qped7.execute-api.ap-southeast-1.amazonaws.com/production/graphql',
  apigToken: 'xMTIv2QesoR19y4Z15R13P0dBh6Dxta33aDJu1r3',
  fbAppID: '441547156274752',
  googleAppID:
    '668559931160-djjnhlkoon4apvqlkuqq05vccu421qv5.apps.googleusercontent.com',
  ravenUrl: 'https://00cf535c8ee4441b889b6c83ae97b666@sentry.io/1218362',
  annotatorUrl:
    'https://tz29cbygp0.execute-api.ap-southeast-1.amazonaws.com/dev',
  mbrUrl: 'https://scoop-auto-formatter.trypencil.com',
  optimizelyToken: '87RLCd4sJW2qUTwfZPUBaz',
  };
