import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from './../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';
import { AlertService } from '../../services/alert/alert.service';
import { SpinnerService } from '../../services/spinner/spinner.service';

declare const gapi: any;

@Injectable()
export class GoogleService {
  constructor(
    private router: Router,
    private auth: AuthService,
    private userService: UserService,
    private alertService: AlertService,
    private appSpinner: SpinnerService,
    private zone: NgZone
  ) {}

  renderButton() {
    gapi.signin2.render('g-signin2', {
      scope: 'profile email',
      width: 237,
      height: 40,
      longtitle: true,
      theme: 'dark',
      onsuccess: this.onSuccess.bind(this),
      onfailure: this.onFailure.bind(this),
    });
  }

  onSuccess(googleUser) {
    const profile = googleUser.getBasicProfile();
    const userInfo = {
      id: profile.getId(),
      name: profile.getName(),
      email: profile.getEmail(),
    };

    const { id_token } = googleUser.getAuthResponse();

    // Prevent Google redirect
    return gapi.auth2
      .getAuthInstance()
      .disconnect()
      .then(() => {
        return this.userService
          .oauthSignin({
            provider: 'google',
            token: id_token,
            email: userInfo.email,
            name: userInfo.name,
            googleId: userInfo.id,
          })
          .subscribe(
            ({ data }) => {
              const { token } = data.oauthSignin;
              localStorage.setItem('pencil-hit-token', token);

              this.zone.run(() => {
                this.router.navigate(['/tasks']);
              });
            },
            error => {
              const errorMsg = error.message.split(':')[1].trim();
              this.alertService.contentAlert = {
                type: 'warning',
                text: 'Please log in to access the page.',
                closable: false,
              };

              if (errorMsg === 'UserNotWhitelisted') {
                this.alertService.contentAlert.text =
                  'Your email is invalid. Please drop us an email at <a href="mailto:contact-us@trytasks.com" target="_top">contact-us@trytasks.com</a>';
              }

              this.zone.run(() => {
                this.router.navigate(['/login']);
              });
            }
          );
      });
  }

  onFailure(error) {
    // Force a refresh because navigation/hide spinner doesnt work
    window.location.href = '';
  }
}
