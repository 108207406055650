import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object, filter?: string[]) {
    const keys = Object.keys(data);

    // Only keep the keys mapped to string values i.e. { 1: 'copy' }
    // leave out the { 'copy': 1 } part.
    const keysToReturn = keys.slice(keys.length / 2);

    // If filter is present, return keys that are not in the  filter
    if (filter) {
      return _.filter(keysToReturn, k => !_.includes(filter, k));
    }

    return keysToReturn;
  }
}
