import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FeatureFlagService } from '../../services/featureFlag/featureflag.service';

@Component({
  selector: 'app-annotator',
  templateUrl: './annotator.component.html',
  styleUrls: ['./annotator.component.scss'],
})
export class AnnotatorComponent implements OnInit {
  taskId: number = 1;
  showNewAnnotator$: any;

  constructor(
    private route: ActivatedRoute,
    private featureFlags: FeatureFlagService
  ) {
    this.route.params.subscribe(params => {
      this.taskId = Math.max(parseInt(params.id, 10) - 999990, 0);
    });
  }

  ngOnInit() {
    this.showNewAnnotator$ = this.featureFlags.getFeature(
      'TASKS_USE_NEW_ANNOTATOR'
    );
  }
}
