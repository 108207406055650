import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { AnnotatorComponent } from './components/annotator/annotator.component';
import { AuthGuard } from './services/auth/auth.guard';
import { Roles } from './enums/roles.enum';
var ɵ0 = {
    role: Roles.admin,
}, ɵ1 = {
    role: Roles.registered_user,
}, ɵ2 = {
    role: Roles.registered_user,
};
var routes = [
    // { path: '', redirectTo: 'home', pathMatch: 'full' },
    // { path: '', component: HomeComponent },
    { path: '', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'tasks', component: TasksComponent, canActivate: [AuthGuard] },
    {
        path: 'task',
        loadChildren: './modules/task/task.module#TaskModule',
        canLoad: [AuthGuard],
    },
    {
        path: 'admin',
        loadChildren: './modules/admin/admin.module#AdminModule',
        canLoad: [AuthGuard],
        data: ɵ0,
    },
    {
        path: 'activity',
        loadChildren: './modules/activity/activity.module#ActivityModule',
        canLoad: [AuthGuard],
        data: ɵ1,
    },
    {
        path: 'annotator/:id',
        component: AnnotatorComponent,
        canLoad: [AuthGuard],
        data: ɵ2,
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
