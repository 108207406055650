import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MbrService {
  private mbrUrl: string = environment.mbrUrl;
  constructor(private http: HttpClient) {}

  getBoundingBox(points): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(
      this.mbrUrl + '/api/mbr',
      { data: points },
      {
        headers: httpHeaders,
      }
    );
  }
}
