/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@clr/angular/clr-angular.ngfactory";
import * as i3 from "@clr/angular";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/angular2-markdown/markdown/markdown.component.ngfactory";
import * as i6 from "angular2-markdown/markdown/markdown.component";
import * as i7 from "angular2-markdown/markdown/markdown.service";
import * as i8 from "../task-profiling-modal/task-profiling-modal.component.ngfactory";
import * as i9 from "../task-profiling-modal/task-profiling-modal.component";
import * as i10 from "@angular/router";
import * as i11 from "../tasks.service";
import * as i12 from "./task-modal.component";
import * as i13 from "../../../services/user/user.service";
import * as i14 from "../../../services/spinner/spinner.service";
import * as i15 from "../../../services/auth/auth.service";
var styles_TaskModalComponent = [i0.styles];
var RenderType_TaskModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskModalComponent, data: {} });
export { RenderType_TaskModalComponent as RenderType_TaskModalComponent };
function View_TaskModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "clr-col-xs-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-outline"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onValidate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Validate "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.task.validation; _ck(_v, 1, 0, currVal_0); }); }
function View_TaskModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "clr-modal", [], [[2, "open", null]], [[null, "clrModalOpenChange"], ["body", "keyup.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("body:keyup.escape" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).close() !== false);
        ad = (pd_0 && ad);
    } if (("clrModalOpenChange" === en)) {
        var pd_1 = ((_co.openModal = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ClrModal_0, i2.RenderType_ClrModal)), i1.ɵprd(8704, null, i3.ɵdl, i3.ɵdl, [i4.DOCUMENT]), i1.ɵprd(1024, null, i3.ɵdh, i3.ɵdi, []), i1.ɵdid(3, 704512, null, 0, i3.ClrModal, [i3.ɵdl, i3.ClrCommonStrings, i3.ɵdh], { _open: [0, "_open"], closable: [1, "closable"], size: [2, "size"] }, { _openChanged: "clrModalOpenChange" }), (_l()(), i1.ɵeld(4, 0, null, 1, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, 2, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["Markdown", ""]], null, null, null, i5.View_MarkdownComponent_0, i5.RenderType_MarkdownComponent)), i1.ɵdid(8, 4308992, null, 0, i6.MarkdownComponent, [i7.MarkdownService, i1.ElementRef, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵted(9, 0, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, 3, 10, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 9, "div", [["class", "modal-actions clr-row clr-justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "clr-col-xs-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-outline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Go Back"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TaskModalComponent_2)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "clr-col-xs-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onProceed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Proceed "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.openModal; var currVal_2 = false; var currVal_3 = "lg"; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); _ck(_v, 8, 0); var currVal_6 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_co.auth.isAdmin$)); _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3)._open; _ck(_v, 0, 0, currVal_0); var currVal_4 = _co.task.description; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.task.instructions; _ck(_v, 9, 0, currVal_5); var currVal_7 = (!_co.isValidated || (_co.task.progress === 100)); _ck(_v, 19, 0, currVal_7); }); }
export function View_TaskModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-task-profiling-modal", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.onProfileModalClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_TaskProfilingModalComponent_0, i8.RenderType_TaskProfilingModalComponent)), i1.ɵdid(3, 114688, null, 0, i9.TaskProfilingModalComponent, [i10.Router, i11.TasksService], { openModal: [0, "openModal"], task: [1, "task"], taskProfileQuestions: [2, "taskProfileQuestions"] }, { closeModal: "closeModal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openModal; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.openProfileModal; var currVal_2 = _co.task; var currVal_3 = _co.taskProfileQuestions; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_TaskModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-modal", [], null, null, null, View_TaskModalComponent_0, RenderType_TaskModalComponent)), i1.ɵdid(1, 245760, null, 0, i12.TaskModalComponent, [i10.Router, i13.UserService, i11.TasksService, i14.SpinnerService, i15.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskModalComponentNgFactory = i1.ɵccf("app-task-modal", i12.TaskModalComponent, View_TaskModalComponent_Host_0, { openModal: "openModal", task: "task", isValidated: "isValidated" }, { closeModal: "closeModal" }, []);
export { TaskModalComponentNgFactory as TaskModalComponentNgFactory };
