import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as _ from 'lodash';
import { MarkdownService } from 'angular2-markdown';

import { TasksService } from './tasks.service';
import { AlertService } from '../../services/alert/alert.service';
import { AuthService } from '../../services/auth/auth.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { SettingsService } from '../../services/settings/settings.service';
import { ImageService } from '../../services/annotator/image.service';
import { TaskTypes } from '../../enums/taskTypes.enum';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit, OnDestroy {
  private subscribe: boolean = true;
  tasks: any;
  displayedTasks: any;
  taskTypes: any;
  selectedTask: any;
  isValidated: boolean = false;
  loading: boolean = true;
  openModal: boolean = false;
  alertMessage: string;

  constructor(
    private tasksService: TasksService,
    private mdService: MarkdownService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    public alertService: AlertService,
    public appSpinner: SpinnerService,
    public auth: AuthService,
    private imageService: ImageService
  ) {
    this.router.events.subscribe(() => {
      setTimeout(() => {
        this.alertService.contentAlert = null;
      }, 2000);
    });
  }

  ngOnInit() {
    this.taskTypes = TaskTypes;
    this.appSpinner.showSpinner();

    return this.tasksService
      .getTasks(this.auth.isAdmin$.value)
      .pipe(
        takeWhile(() => this.subscribe),
        map(({ data }) => {
          const tasksForUser = _.map(data.allTasks, t => ({
            progress: Math.min((t.currentLabelCount / t.maxLabels) * 100, 100),
            ...t,
          }));

          return <any>of(tasksForUser);
        }),
        catchError(error => {
          console.log(error);
          // Treat as no tasks found
          return of([]);
        })
      )
      .subscribe(
        ({ value }) => {
          if (_.isUndefined(value)) {
            this.tasks = [];

            return null;
          }

          this.tasks = value;

          //NOTE: Adding new annotator task here
          this.imageService.getTasks().subscribe(
            tasks => {
              this.tasks = _.concat(tasks, this.tasks);
              _.each(this.tasks, t => {
                const html = this.mdService.compile(
                  this.prepareMd(t.instructions)
                );
                t.shortDesc = this.obtainShortDesc(html);
              });

              this.displayedTasks = this.tasks;
              this.appSpinner.hideSpinner();
            },
            error => {
              console.log(`No annotator task`);

              this.displayedTasks = this.tasks;
              this.appSpinner.hideSpinner();
            }
          );
        },
        error => console.log(error)
      );
  }

  ngOnDestroy() {
    if (this.subscribe) {
      this.subscribe = false;
    }
  }

  showTaskType(taskType) {
    if (!taskType) {
      this.displayedTasks = this.tasks;
    } else {
      this.displayedTasks = _.filter(this.tasks, t => {
        return t.taskType.id === this.taskTypes[taskType];
      });
    }
  }

  onTaskSelect(taskId) {
    this.appSpinner.showSpinner();
    this.selectedTask = _.find(this.tasks, { id: taskId });

    this.tasksService.getValidationCount(this.selectedTask.id).subscribe(
      ({ data }) => {
        const validationCount = data.validationCount;
        const minValidation = parseInt(
          this.settingsService.findValue('MINIMUM_VALIDATION_SET'),
          10
        );

        // If task does not require validation, set this to true
        this.isValidated =
          validationCount >= minValidation || !this.selectedTask.validation;
        this.openModal = true;
      },
      error => {
        console.log(error);
        this.appSpinner.hideSpinner();

        this.alertService.contentAlert = {
          type: 'danger',
          text: `An error has occured. If this error persists, please drop us an
          email at <a href="mailto:contact-us@trytasks.com" target="_top">contact-us@trytasks.com</a>.`,
          closable: false,
        };

        setTimeout(() => {
          this.alertService.contentAlert = null;
        }, 2000);
      }
    );
  }

  onModalClose(closed: boolean) {
    this.openModal = false;
    this.appSpinner.hideSpinner();
  }

  obtainShortDesc(compiled: string) {
    // lazy match the first p tag
    const shortDesc = /<p>(.*?)<\/p>/.exec(compiled);

    // return first capturing group
    return shortDesc && shortDesc[1];
  }

  /**
   * Extracted from angular2-markdown. Used to parse a raw markdown string.
   * @see https://github.com/dimpu/ngx-md/blob/master/src/markdown/markdown.component.ts#L102
   */
  private prepareMd(raw: string) {
    if (!raw) {
      return '';
    }

    let isCodeBlock = false;

    return raw
      .split('\n')
      .map((line: string) => {
        if (this.trimLeft(line).substring(0, 3) === '```') {
          isCodeBlock = !isCodeBlock;
        }

        return isCodeBlock ? line : line.trim();
      })
      .join('\n');
  }

  private trimLeft(line: string) {
    return line.replace(/^\s+|\s+$/g, '');
  }
}
