import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';

import { UserService } from '../../../services/user/user.service';
import { TasksService } from '../../tasks/tasks.service';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss'],
})
export class TaskModalComponent implements OnInit, OnDestroy {
  @Input() openModal: boolean;
  @Input() task: any;
  @Input() isValidated: boolean;
  @Output() closeModal = new EventEmitter<boolean>();
  private subscribe: boolean = true;
  user: any;
  openProfileModal: boolean = false;
  taskProfileQuestions: any;

  constructor(
    private router: Router,
    private userService: UserService,
    private tasksService: TasksService,
    private appSpinner: SpinnerService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.getUserProfile();
  }

  ngOnDestroy() {
    if (this.subscribe) {
      this.subscribe = false;
    }
  }

  private getUserProfile() {
    this.userService
      .me()
      .pipe(takeWhile(() => this.subscribe))
      .subscribe(
        ({ data }) => {
          this.user = data.me;
        },
        error => console.log(error)
      );
  }

  onProceed() {
    // reset openModal property on the parent
    this.closeModal.emit(true);
    this.openModal = false;

    //NOTE: Adding navigation to new Annotator task here.
    if (this.task.id > 999990) {
      return this.router.navigate([`/annotator/${this.task.id}`]);
    }

    const hasCompletedTaskProfiling = _.includes(
      this.user.completedTaskProfiles,
      this.task.id
    );

    if (hasCompletedTaskProfiling) {
      return this.router.navigate([`/task/${this.task.id}`]);
    }

    this.appSpinner.showSpinner();

    return this.tasksService
      .getTaskProfileQuestions(this.task.id)
      .pipe(takeWhile(() => this.subscribe))
      .subscribe(
        ({ data }) => {
          this.taskProfileQuestions = data.taskProfileQuestions;

          if (_.isEmpty(this.taskProfileQuestions)) {
            return this.router.navigate([`/task/${this.task.id}`]);
          }

          this.openProfileModal = true;
          this.appSpinner.hideSpinner();

          return null;
        },
        error => console.log(error)
      );
  }

  onClose() {
    // reset openModal property on the parent
    this.closeModal.emit(true);
    this.openModal = false;
  }

  onValidate() {
    // reset openModal property on the parent
    this.closeModal.emit(true);
    this.openModal = false;

    this.appSpinner.showSpinner();

    return this.router.navigate([
      `/task/${this.task.id}`,
      { validation: true },
    ]);
  }

  onProfileModalClose(closed: boolean) {
    this.openProfileModal = false;
  }
}
